import isEqual from 'lodash/isEqual'
import { distinctUntilChanged, map, takeWhile } from 'rxjs'

import PaperTool from '../paperTool/PaperTool'
import Zoom from '../zoom/Zoom.tool'
import { FlagEnum } from '../../../../../../models/flags'
import { initial2DState } from '../../../../../slices/2D'
import { Coordinates2D, PaperToolConfig, Vector2D, VIEW_MODE } from '../../../../../types'
import addSelectFunctionalityToFlag from '../../../../utils/functionality-bindings/addSelectFunctionalityToFlag'

/**
 * Flag.tool.tsx
 * Creates a flag -- used for adding task and notes to page and materials
 */
export class Flag extends PaperTool {
    static NAME = 'FLAG'

    private static FLAG_TASK_COLOR = '#FF0000'
    private static FLAG_NOTE_COLOR = '#194185'
    private static FLAG_SQUARE_RADIUS = 5
    private static FLAG_SQUARE_SIZE_HEIGHT = 50
    // size of the square when content is placeholder (#) or not more when content length is 2
    private static FLAG_SQUARE_SIZE_BASE_WIDTH = 50
    // space left when the content of the box is less than 3 lengths
    private static FLAG_CONTENT_SPACE_LEFT = 17
    // size of the square when content length is 3
    private static FLAG_SQUARE_SIZE_WIDTH_WITH_CONTENT = 62
    // space left when the content of the box is 3 lengths
    private static FLAG_CONTENT_SPACE_LEFT_WITH_CONTENT = 8
    private static FLAG_SQUARE_ARROW_Y_OFFSET = 5

    private currentZoom = initial2DState.currentZoom

    static INCREMENT = Zoom.INCREMENT
    static MIN_ITEM_SCALE = 0.2 // minimal item scale, change it if we need smaller items
    static MAX_ITEM_SCALE = 1 // normal item scale, should not be changed
    static MIN_FLAGS_ZOOM = 0.5 // when scale is less then 0.5, we do not scale more
    static MAX_FLAGS_ZOOM = 3 // when scale is more then 3, we do not scale more

    private cachedFlags: paper.Group[] = []
    private materialFlagOffset = 25

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Flag.NAME

        this.mediator
            .get$()
            .pipe(
                takeWhile((state) => isEqual(state.common.activeMode, VIEW_MODE.Markup2D)),
                map((state) => {
                    return {
                        currentZoom: state['2D'].currentZoom,
                    }
                }),
                distinctUntilChanged()
            )
            .subscribe(({ currentZoom: nextZoom }) => {
                this.scaleFlags(nextZoom)
            })
    }

    /**
     * Calculate the square size based on content
     * @param content
     */
    private calculateFlagSquareSize = (content: string): number => {
        if (content.length < 3) return Flag.FLAG_SQUARE_SIZE_BASE_WIDTH

        return Flag.FLAG_SQUARE_SIZE_WIDTH_WITH_CONTENT
    }

    /**
     * Calculate the content left space base on content
     * @param content
     */
    private calculateFlagContentLeftSpace = (content: string): number => {
        if (content.length === 1) return Flag.FLAG_CONTENT_SPACE_LEFT

        return Flag.FLAG_CONTENT_SPACE_LEFT_WITH_CONTENT
    }

    private getFlagColor = (flagType: string | null): string => {
        return flagType === null || flagType === FlagEnum.TASK ? Flag.FLAG_TASK_COLOR : Flag.FLAG_NOTE_COLOR
    }

    private drawFlagSquare = (x: number, y: number, flagSize: number) => {
        const cornerRadius = new this.paper.Size(Flag.FLAG_SQUARE_RADIUS, Flag.FLAG_SQUARE_RADIUS)

        const flagSquare = new this.paper.Path.Rectangle({
            // + 1 used to prevent appear line between square and square arrow
            bottomCenter: [x, y + 1],
            size: [flagSize, Flag.FLAG_SQUARE_SIZE_HEIGHT],
            radius: cornerRadius,
        })

        return flagSquare
    }

    private drawFlagSquareArrow = (x: number, y: number) => {
        const flagSquareArrow = new this.paper.Path.RegularPolygon({
            center: new this.paper.Point(x, y - Flag.FLAG_SQUARE_ARROW_Y_OFFSET), // Adjust the center position
            sides: 3, // Triangle shape for the arrow
            radius: 10, // Adjust the arrow size
            rotation: 180, // Rotate it to point downward
        })

        return flagSquareArrow
    }

    private drawFlagSquareContent = (flagSquare: paper.Path.Rectangle, content: string) => {
        const leftSpace = this.calculateFlagContentLeftSpace(content)

        const flagPointText = new this.paper.PointText({
            point: flagSquare.bounds.leftCenter.add(new this.paper.Point(leftSpace, 10)),
            content: content,
            fontSize: 30,
            fillColor: 'white',
            fontWeight: 'bold',
            locked: true,
        })

        return flagPointText
    }

    private drawFlag = (
        x: number,
        y: number,
        flagContent = '#',
        flagColor = Flag.FLAG_TASK_COLOR,
        flagSize = Flag.FLAG_SQUARE_SIZE_BASE_WIDTH
    ) => {
        const flagSquareArrow = this.drawFlagSquareArrow(x, y)

        const flagSquare = this.drawFlagSquare(
            flagSquareArrow.bounds.topCenter.x,
            flagSquareArrow.bounds.topCenter.y,
            flagSize
        )

        const flagPointText = this.drawFlagSquareContent(flagSquare, flagContent)

        flagPointText.bringToFront()

        // group to make one item clickable, instead square and arrow separately
        const flagSquareWithArrow = new this.paper.CompoundPath({
            children: [flagSquareArrow, flagSquare],
            fillColor: flagColor,
        })

        // prevent adding handles
        flagSquareWithArrow.data.selectable = false

        const flagGroup = new this.paper.Group([flagSquareWithArrow, flagPointText])

        // add select functionality to Group , as a result if one of the item of the group is selected the group will be selected instead
        addSelectFunctionalityToFlag(flagGroup)

        // scale new item with the same scaling that existing items
        flagGroup.scale(this.getCurrentFlagsScaling())

        // put the flag in correct position
        flagGroup.bounds.bottomCenter = new this.paper.Point(x, y)

        return flagGroup
    }

    private drawFlagOnClick = (event: paper.ToolEvent) => {
        const x = event.point.x
        const y = event.point.y

        const hit = this.getPaperScope().project.hitTest(event.point)

        const flagGroup = this.drawFlag(x, y)

        const documentChunk = this.getActiveDocumentChunk()

        flagGroup.data.toolName = this.name
        flagGroup.data.isTemporaryGroup = true
        flagGroup.data.opening_id = hit?.item?.data?.drawable_id || null
        flagGroup.data.document_chunk_id = documentChunk?.id || null
    }

    drawFlagByCoords = (
        coords: [number, number],
        flag_id: number,
        openingId: number | null,
        order: number,
        type: string | null,
        isTemporaryGroup = false
    ) => {
        const x = coords[0]
        const y = coords[1]

        const flagContent = order.toString()

        const flagSize = this.calculateFlagSquareSize(flagContent)

        const flagColor = this.getFlagColor(type)

        const flagGroup = this.drawFlag(x, y, flagContent, flagColor, flagSize)

        flagGroup.data.toolName = this.name
        flagGroup.data.flag_id = flag_id
        flagGroup.data.isTemporaryGroup = isTemporaryGroup
        flagGroup.data.openingId = openingId

        this.addCachedFlags([flagGroup])

        return flagGroup
    }

    getFlagPointFromCoordinates = (coords: Coordinates2D, isFlagCentered: boolean, flagIndex?: number): Vector2D => {
        const adjustY = (y: number) => y - Flag.FLAG_SQUARE_SIZE_HEIGHT

        let centerPointX: number
        let centerPointY: number

        const adjustedCoordinatesByIndex = flagIndex ? flagIndex * this.materialFlagOffset : 0

        // center point for area or dot shapes
        if (isFlagCentered || coords.length === 1) {
            ;[centerPointX, centerPointY] = coords.reduce(([sumX, sumY], [x, y]) => [sumX + x, sumY + y], [0, 0])

            const adjustedCenterPointY = adjustY(centerPointY / coords.length)

            centerPointX = centerPointX / coords.length

            return [centerPointX + adjustedCoordinatesByIndex, adjustedCenterPointY - adjustedCoordinatesByIndex]
        }

        // general calculation of the point
        const middleItemNumber = Math.floor(coords.length / 2) - 1

        centerPointX = (coords[middleItemNumber][0] + coords[middleItemNumber + 1][0]) / 2
        centerPointY = adjustY((coords[middleItemNumber][1] + coords[middleItemNumber + 1][1]) / 2)

        return [centerPointX + adjustedCoordinatesByIndex, centerPointY - adjustedCoordinatesByIndex]
    }

    scaleFlags(nextZoom: number) {
        if (this.cachedFlags.length === 0) return

        if (this.currentZoom === nextZoom) return

        if (nextZoom <= Flag.MIN_FLAGS_ZOOM || nextZoom >= Flag.MAX_FLAGS_ZOOM) {
            return
        }

        // calculate next scale step based on difference between current and next zoom
        const nextStep = Number(Math.abs(this.currentZoom / nextZoom).toFixed(1))

        const currentScale = this.getCurrentFlagsScaling()
        const potentialScale = currentScale * nextStep

        this.cachedFlags.forEach((item) => {
            const itemBottomCenter = item.bounds.bottomCenter

            // Enforce min and max scale limits
            if (potentialScale > Flag.MAX_ITEM_SCALE) {
                // Cap the scale to the max scale
                item.scale(Flag.MAX_ITEM_SCALE / currentScale)
            } else if (potentialScale < Flag.MIN_ITEM_SCALE) {
                // Cap the scale to the min scale
                item.scale(Flag.MIN_ITEM_SCALE / currentScale)
            } else {
                // Scale normally if within the allowed range
                item.scale(nextStep)
            }

            item.bounds.bottomCenter = itemBottomCenter
        })

        this.currentZoom = nextZoom
    }

    getCachedFlags = () => {
        return this.cachedFlags
    }

    addCachedFlags = (newFlags: paper.Group[]) => {
        // before adding a new flag we remove previous record about it
        const filteredCachedFlags = this.cachedFlags.filter(
            (cachedFlag) =>
                !newFlags.some(
                    (f) => f.data.flag_id === cachedFlag.data.flag_id && f.data.openingId === cachedFlag.data.openingId
                )
        )

        this.cachedFlags = [...filteredCachedFlags, ...newFlags]
    }

    removeCachedFlagsByDataFlagId = (flagIds: number[]) => {
        this.cachedFlags = this.cachedFlags.filter((cachedFlag) => !flagIds.includes(cachedFlag.data.flag_id))
    }

    resetCachedFlags = () => {
        this.cachedFlags = []
    }

    getCurrentFlagsScaling = () => {
        if (this.cachedFlags.length > 0) {
            return Math.min(this.cachedFlags[0].children[1].scaling.x, this.cachedFlags[0].children[1].scaling.y)
        }

        return 1
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        this.drawFlagOnClick(event)

        this.setState('materialFlags', { isOpenFlagForm: true })
    }

    getFlagCoordinates = (flagGroup: paper.Item) => {
        const flagSquare: paper.Path.Rectangle = flagGroup.children[0].children[0] as paper.Path.RegularPolygon

        return [flagSquare.position.x, flagSquare.position.y + Flag.FLAG_SQUARE_ARROW_Y_OFFSET]
    }
}

export default Flag
