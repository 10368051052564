import { AISuggestion } from '../../../../models/aiClassifications'
import store from '../../../../stores'
import { updateSelectedAISuggestion } from '../../../sagas/2D/updateSelectedAISuggestion'
import { selectedPaperItem, updateSelectedMaterialType } from '../../../slices/2D'

function selectAiSuggestion(): () => void {
    return function selectDrawable(this: paper.Path) {
        const drawable = this.data.aiSuggestion as AISuggestion

        store.dispatch(updateSelectedMaterialType(drawable.type))
        store.dispatch(
            updateSelectedAISuggestion({ aiSuggestionIDs: [this.data.aiSuggestion.id], aiDrawableID: this.id })
        )
    }
}

function deselectAiSuggestion(): () => void {
    return function deselectDrawable(this: paper.Path) {
        store.dispatch(
            selectedPaperItem({
                selectedItem: null,
                activeDrawableId: null,
                activeOpeningLocationId: null,
                openingGroupId: null,
            })
        )
    }
}

export default function addSelectFunctionalityToAiSuggestion(drawable: paper.PathItem): void {
    drawable.data.onSelect = selectAiSuggestion().bind(drawable)
    drawable.data.onDeselect = deselectAiSuggestion().bind(drawable)
}
