import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    location: {
        'ui:widget': 'hidden',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return { ...settings, ...formData }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
