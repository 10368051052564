import { createAction } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'

import { selectProject } from './createDrawableLocation'
import { handleToggleMaterialFlagsVisibility } from './handleToggleMaterialFlagsVisibility'
import { toggleRegionLinesVisibilityAction } from './handleToggleRegionLinesVisibility'
import { createFlag } from '../../../api/projects-api'
import { FlagAPIPayload, FlagEnum, FlagFormData, NewOpeningLink, OpeningLink } from '../../../models/flags'
import { Project } from '../../../models/project'
import { DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import { filterNullValuesFromObject } from '../../../utils/general'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Flag, Workspace } from '../../lib/toolBoxes/2D'
import { selectActiveFloorId } from '../../slices/documents'
import { ActiveGeometryGroup, selectActiveGeometryGroup } from '../../slices/geometry'
import { addNewFlag, selectMaterialFlagIndexByOpeningId } from '../../slices/materialFlags'
import { setFlagsVisibility } from '../../slices/tools'
import { Vector2D } from '../../types'

export const createFlagAction = createAction<FlagFormData>('createFlag')

export function* handleCreateFlag({ payload }: ReturnType<typeof createFlagAction>) {
    try {
        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (!manager) return

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)
        const flagTool: Flag = yield call(manager.getTool, Flag.NAME)

        const project: Project = yield select(selectProject)
        const activeFloorId: number = yield select(selectActiveFloorId)
        const activeGeometryGroup: ActiveGeometryGroup | null = yield select(selectActiveGeometryGroup)
        const materialFlagIndexByOpeningId: ReturnType<typeof selectMaterialFlagIndexByOpeningId> = yield select(
            selectMaterialFlagIndexByOpeningId
        )

        // update flags visibility in store
        yield put(setFlagsVisibility(true))

        // make material flags visible on blueprint
        yield call(handleToggleMaterialFlagsVisibility, {
            payload: true,
            type: toggleRegionLinesVisibilityAction.type,
        })

        const temporaryFlagItems: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            (data) => data?.toolName === Flag.NAME && data?.isTemporaryGroup
        )

        const openingLinks: NewOpeningLink[] = []

        // in case when a flag is created from material itself
        if (!temporaryFlagItems.length) {
            const openingLinksData = activeGeometryGroup?.openings
                ?.filter((drawable) => drawable.isActive)
                ?.map((drawable) => ({
                    drawable_id: drawable.id,
                    coordinates: drawable.opening_locations[0].coordinates,
                    document_chunk_id: drawable.opening_locations[0].document_chunk_id,
                }))

            if (openingLinksData) {
                for (const openingLinkData of openingLinksData) {
                    const drawableAreaItems: paper.Item[] = yield call(
                        workspaceTool.getItemsWithCriteria,
                        'data',
                        (data) =>
                            data.drawable_id === openingLinkData.drawable_id && data.shapeType === DRAWING_TYPES.AREA
                    )

                    const nextIndex = Object.keys(materialFlagIndexByOpeningId[openingLinkData.drawable_id]).length

                    const drawableCenterCoordinates: Vector2D = yield call(
                        flagTool.getFlagPointFromCoordinates,
                        openingLinkData.coordinates,
                        !!drawableAreaItems.length,
                        nextIndex
                    )

                    openingLinks.push({
                        opening_id: openingLinkData.drawable_id,
                        document_chunk_id: openingLinkData.document_chunk_id,
                        coordinates: drawableCenterCoordinates,
                    })
                }
            }
        } else {
            temporaryFlagItems.forEach((flag) => {
                openingLinks.push({
                    opening_id: flag.data.opening_id,
                    coordinates: [flag.bounds.bottomCenter.x, flag.bounds.bottomCenter.y],
                    document_chunk_id: flag.data.document_chunk_id,
                })
            })
        }

        const response = yield call(createFlag, project.id, {
            ...filterNullValuesFromObject(payload),
            opening_links: openingLinks,
        } as FlagAPIPayload)

        const updatedOpeningLinks: OpeningLink[] = response?.opening_links.filter(
            (link: OpeningLink) => link.document_chunk_id === activeFloorId
        )

        if (!!updatedOpeningLinks?.length) {
            // remove flags
            yield all(temporaryFlagItems.map((item) => item.remove()))

            const type = response.type_id === 1 ? FlagEnum.TASK : FlagEnum.NOTE

            // add saved flags to blueprint
            yield all(
                updatedOpeningLinks.map((link) =>
                    call(
                        flagTool.drawFlagByCoords,
                        link.coordinates,
                        response.id,
                        link.opening_id,
                        response.order,
                        type
                    )
                )
            )

            // save flags in store
            yield put(addNewFlag({ flag: response }))
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForCreateFlag(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(createFlagAction.type, handleCreateFlag)
}
