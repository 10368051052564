import { Opening_location, OpeningGroup } from './../../../../models/activeDrawable'
import { DRAWABLE_TYPES } from '../../../../shared/constants/drawable-types'
import { GeometryGroup } from '../../../slices/geometry'
import { ThreeDToTwoDRecord } from '../../../types'

export function determine3DId({
    openingIdsToMeshIdsMap,
    threeDIdentifier,
    groupType,
    settings,
}: {
    openingIdsToMeshIdsMap: Record<string, string> | null
    threeDIdentifier: string
    groupType: DRAWABLE_TYPES
    settings: OpeningGroup['settings']
}) {
    if (openingIdsToMeshIdsMap && openingIdsToMeshIdsMap[threeDIdentifier]) {
        if (groupType === DRAWABLE_TYPES.HEADER) {
            return openingIdsToMeshIdsMap[threeDIdentifier + '-HEADER']
        } else if (groupType === DRAWABLE_TYPES.TRIM) {
            if (settings.location?.match(/(DOOR JAMB)/g)) {
                return openingIdsToMeshIdsMap[threeDIdentifier + '-HEADER']
            } else if (settings.location?.match(/(TOP)/g)) {
                return openingIdsToMeshIdsMap[threeDIdentifier + '-HEADER']
            } else if (settings.location?.match(/BOTTOM/g)) {
                return openingIdsToMeshIdsMap[threeDIdentifier + '-SILL']
            }

            return openingIdsToMeshIdsMap[threeDIdentifier + '-CASING']
        }

        return openingIdsToMeshIdsMap[threeDIdentifier]
    }

    return threeDIdentifier
}

/**
 * Prepares the mappings between 3D meshes to 2D items
 * @returns object to map between 3D meshes and 2D items
 */
export function* prepare3DTo2DMapping(
    openingIdsToMeshIdsMap: Record<string, string> | null,
    drawableGroups: GeometryGroup[]
): Generator<ThreeDToTwoDRecord, any, unknown> {
    const mappings = yield drawableGroups
        .flatMap((drawableGroup) =>
            drawableGroup.openings.map((opening) => ({
                ...opening,
                groupId: drawableGroup.id,
                type: drawableGroup.type,
            }))
        )
        .flatMap((opening) =>
            opening.opening_locations.reduce(
                (filteredLocations, location) =>
                    !!location.three_d_identifier &&
                    (!location.additional_data || (location.additional_data && !location.additional_data.joist_length))
                        ? [
                              ...filteredLocations,
                              {
                                  ...location,
                                  openingId: opening.id,
                                  groupId: opening.groupId,
                                  type: opening.type,
                                  settings: opening.settings,
                              },
                          ]
                        : filteredLocations,
                [] as (Opening_location & {
                    openingId: number
                    groupId: number
                    type: DRAWABLE_TYPES
                    settings: OpeningGroup['settings']
                })[]
            )
        )
        .reduce((threeDToTwoDMappings, { id, openingId, groupId, three_d_identifier, type, settings }) => {
            if (three_d_identifier) {
                const threeDid = determine3DId({
                    openingIdsToMeshIdsMap,
                    threeDIdentifier: three_d_identifier,
                    groupType: type,
                    settings,
                })

                !threeDToTwoDMappings[threeDid]
                    ? (threeDToTwoDMappings[threeDid] = [
                          {
                              locationId: id!,
                              openingId,
                              groupId,
                              type,
                          },
                      ])
                    : threeDToTwoDMappings[threeDid].push({
                          locationId: id!,
                          openingId,
                          groupId,
                          type,
                      })
            }

            return threeDToTwoDMappings
        }, {} as ThreeDToTwoDRecord)

    return mappings
}
