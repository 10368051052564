import { DRAWABLE_UNITS_OF_MEASURE } from '../imup/types'
import { DRAWING_TYPES } from '../shared/constants/drawable-types'

export const getUnitOfMeasureByDrawingType = (drawingType) => {
    switch (drawingType) {
        case DRAWING_TYPES.SECTION:
        case DRAWING_TYPES.RADIUS:
            return DRAWABLE_UNITS_OF_MEASURE.line
        case DRAWING_TYPES.AREA:
            return DRAWABLE_UNITS_OF_MEASURE.area
        default:
            return DRAWABLE_UNITS_OF_MEASURE.count
    }
}

export const filterNullValuesFromObject = <T extends object>(object: T): { [K in keyof T]: Exclude<T[K], null> } => {
    return Object.fromEntries(Object.entries(object).filter(([_, value]) => value !== null)) as {
        [K in keyof T]: Exclude<T[K], null>
    }
}
