import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { selectActiveGeometryGroup } from './geometry'
import {
    FlagAPIResponse,
    FlagAxiosAPIResponse,
    FlagEnum,
    FlagOpeningLinksAPIUpdatePayload,
    FlagOption,
    FlagOptions,
    NormalizedFlag,
} from '../../models/flags'
import { RootState } from '../../stores'

export type MaterialFlagsState = {
    flags: NormalizedFlag[]
    activeFlag: NormalizedFlag | null
    flagOptions: FlagOptions
    isOpenFlagForm: boolean
    isTaskOrNoteInEditMode: boolean
}

export const initialMaterialFlagsState: MaterialFlagsState = {
    flags: [],
    activeFlag: null,
    flagOptions: {
        types: [],
        statuses: [],
        severities: [],
        categories: [],
        requirementSources: [],
        subCategories: [],
    },
    isOpenFlagForm: false,
    isTaskOrNoteInEditMode: false,
}

const getOptionData = (options: FlagOption[], id: number | null | undefined) => {
    if (!id || !options?.length) return null

    return options.find((option) => option.id === id)
}

const normalizeFlags = (flags: FlagAPIResponse[], flagOptions: FlagOptions): NormalizedFlag[] => {
    return flags.map((flag: FlagAPIResponse) => {
        const updatedFlag = {
            ...flag,
            type: getOptionData(flagOptions!.types, flag.type_id),
            status: getOptionData(flagOptions!.statuses, flag.status_id),
            severity: getOptionData(flagOptions!.severities, flag.severity_id),
            category: getOptionData(flagOptions!.categories, flag.category_id),
            requirement_sources: getOptionData(flagOptions!.requirementSources, flag.category_id),
            sub_category: getOptionData(flagOptions!.subCategories, flag.sub_category_id),
        }

        // clean up unneeded fields
        delete updatedFlag.type_id
        delete updatedFlag.status_id
        delete updatedFlag.severity_id
        delete updatedFlag.category_id
        delete updatedFlag.requirement_source_id
        delete updatedFlag.sub_category_id

        return updatedFlag
    })
}

// FLAGS
const handleSetFlags = (state: MaterialFlagsState, { payload }: PayloadAction<{ flags: FlagAPIResponse[] }>) => {
    state.flags = normalizeFlags(payload.flags, state.flagOptions)
}

const handleAddNewFlag = (state: MaterialFlagsState, { payload }: PayloadAction<{ flag: FlagAPIResponse }>) => {
    const normalizedFlag = normalizeFlags([payload.flag], state.flagOptions)

    state.flags = [...state.flags, ...normalizedFlag]
}

const handleRemoveFlag = (state: MaterialFlagsState, { payload }: PayloadAction<{ flag_ids: number[] }>) => {
    state.flags = state.flags.filter((flag) => !payload.flag_ids.includes(flag.id))
}

const handleUnlinkMaterialFromFlag = (
    state: MaterialFlagsState,
    { payload }: PayloadAction<{ flag_id: number; opening_link_id: number }>
) => {
    const { flag_id, opening_link_id } = payload

    const updatedFlags = state.flags.map((flag) => {
        if (flag.id === flag_id) {
            return {
                ...flag,
                opening_links: flag.opening_links.filter((link) => link.id !== opening_link_id),
            }
        }

        return flag
    })

    const activeFlagUpdate = updatedFlags.find((flag) => flag.id === flag_id)

    if (activeFlagUpdate && state.activeFlag?.id === flag_id) {
        state.activeFlag = activeFlagUpdate
    }

    state.flags = updatedFlags
}

const handleSetActiveFlag = (state: MaterialFlagsState, { payload }: PayloadAction<{ flag_id: number }>) => {
    state.activeFlag = state.flags.find((flag) => flag.id === payload.flag_id) || null
}

const handleSetTaskNoteEditMode = (state: MaterialFlagsState, { payload }: PayloadAction<{ isEditMode: boolean }>) => {
    state.isTaskOrNoteInEditMode = payload.isEditMode
}

const handleResetActiveFlag = (state: MaterialFlagsState) => {
    state.activeFlag = null
}

const handleUpdateFlagStatus = (state: MaterialFlagsState, { payload }: PayloadAction<FlagAxiosAPIResponse>) => {
    const { id, status_id, description, category_id, requirement_source_id, sub_category_id } = payload.updatedFlag

    const updatedFlags = state.flags.map((flag) => {
        if (flag.id === id) {
            return {
                ...flag,
                status: getOptionData(state.flagOptions!.statuses, status_id),
                category: getOptionData(state.flagOptions!.categories, category_id),
                requirement_source: getOptionData(state.flagOptions!.requirementSources, requirement_source_id),
                sub_category: getOptionData(state.flagOptions!.subCategories, sub_category_id),
                description,
            }
        }

        return flag
    })

    if (state.activeFlag && state.activeFlag.id === id) {
        state.activeFlag = updatedFlags.find((flag) => flag.id === id) || null
    }

    state.flags = updatedFlags
}

const handleUpdateFlagOpeningCoordinates = (
    state: MaterialFlagsState,
    { payload }: PayloadAction<{ newOpeningLinkData: FlagOpeningLinksAPIUpdatePayload[] }>
) => {
    const { newOpeningLinkData } = payload

    const updatedFlags = state.flags.map((flag) => {
        const foundNewLinkData = newOpeningLinkData.find((link) => link.flag_id === flag.id)

        if (foundNewLinkData) {
            return {
                ...flag,
                opening_links: flag.opening_links.map((link) => {
                    if (link.id === foundNewLinkData.id) {
                        return {
                            ...link,
                            coordinates: foundNewLinkData.coordinates,
                        }
                    }

                    return link
                }),
            }
        }

        return flag
    })

    state.flags = updatedFlags
}

const handleSetFlagOptions = (state: MaterialFlagsState, { payload }: PayloadAction<{ options: any }>) => {
    state.flagOptions = {
        types: payload.options.types,
        statuses: payload.options.statuses?.items || [],
        severities: payload.options.severities?.items || [],
        categories: payload.options.categories?.items || [],
        requirementSources: payload.options.requirementSources?.items || [],
        subCategories: payload.options.subCategories?.items || [],
    }
}

const handleUpdateFlag = (state: MaterialFlagsState, { payload }: PayloadAction<FlagAxiosAPIResponse>) => {
    const { updatedFlag } = payload
    //update active flag

    if (updatedFlag.id === state.activeFlag?.id) {
        state.activeFlag = updatedFlag
    }

    state.flags = state.flags.map((flag) => (flag.id === updatedFlag.id ? updatedFlag : flag))
}

// ACTIONS
const handleToggleFlagForm = (state: MaterialFlagsState, { payload }: PayloadAction<{ toggleForm: boolean }>) => {
    state.isOpenFlagForm = payload.toggleForm
}

const reducers = {
    setFlags: handleSetFlags,
    addNewFlag: handleAddNewFlag,
    removeFlag: handleRemoveFlag,
    setActiveFlag: handleSetActiveFlag,
    resetActiveFlag: handleResetActiveFlag,
    updateFlagStatus: handleUpdateFlagStatus,
    updateFlagOpeningCoordinates: handleUpdateFlagOpeningCoordinates,
    unlinkFlagFromMaterial: handleUnlinkMaterialFromFlag,
    updateFlag: handleUpdateFlag,

    setOptions: handleSetFlagOptions,
    toggleFlagForm: handleToggleFlagForm,
    setTaskNoteEditMode: handleSetTaskNoteEditMode,
}

const materialFlagsSlice = createSlice({
    name: 'materialFlags',
    initialState: initialMaterialFlagsState,
    reducers,
})

export const {
    setFlags,
    addNewFlag,
    removeFlag,
    setActiveFlag,
    resetActiveFlag,
    updateFlagStatus,
    updateFlagOpeningCoordinates,
    unlinkFlagFromMaterial,
    updateFlag,

    setOptions,
    toggleFlagForm,
    setTaskNoteEditMode,
} = materialFlagsSlice.actions

export default materialFlagsSlice

export const selectMaterialFlags = createSelector(
    (state: RootState) => state.IMUP.materialFlags.flags,
    (flags) => flags
)

export const selectMaterialActiveFlag = createSelector(
    (state: RootState) => state.IMUP.materialFlags.activeFlag,
    (activeFlag) => activeFlag
)

export const selectFlagOptions = createSelector(
    (state: RootState) => state.IMUP.materialFlags.flagOptions,
    (flagOptions) => flagOptions
)

export const selectIsFlagFormOpen = createSelector(
    (state: RootState) => state.IMUP.materialFlags.isOpenFlagForm,
    (isOpenFlagForm) => isOpenFlagForm
)

export const selectIsTaskOrNoteInEditMode = createSelector(
    (state: RootState) => state.IMUP.materialFlags.isTaskOrNoteInEditMode,
    (isTaskOrNoteInEditMode) => isTaskOrNoteInEditMode
)

export const selectNotLinkedFlagsTaskAndNotes = createSelector(
    [
        (state: RootState) => state.IMUP.materialFlags.flags, // All flags
        selectActiveGeometryGroup, // Use the existing selector
    ],
    (allFlags, activeGeometryGroup) => {
        const notLinkedTasks: NormalizedFlag[] = []
        const notLinkedNotes: NormalizedFlag[] = []

        // Handle the case where there is no active geometry group
        if (!activeGeometryGroup) {
            return { notLinkedTasks, notLinkedNotes }
        }

        const openingIds = activeGeometryGroup.openings.reduce<number[]>((ids, opening) => {
            if (opening.isActive) {
                ids.push(opening.id)
            }

            return ids
        }, [])

        allFlags.forEach((flag) => {
            let isLinked = false

            if (openingIds.length && flag?.opening_links.length) {
                const openingLinksIds = flag.opening_links.map((link) => link.opening_id)

                isLinked = openingIds.every((openingId) => openingLinksIds.includes(openingId))
            }

            if (!isLinked) {
                if (flag.type?.name === FlagEnum.TASK) {
                    notLinkedTasks.push(flag)
                } else if (flag.type?.name === FlagEnum.NOTE) {
                    notLinkedNotes.push(flag)
                }
            }
        })

        return { notLinkedTasks, notLinkedNotes }
    }
)
// this allow to know the index of each flag which is licked to material
export const selectMaterialFlagIndexByOpeningId = createSelector(
    (state: RootState) => state.IMUP.materialFlags.flags,
    (flags) => {
        // Create the flagRecords by opening_id
        const flagRecords = flags.reduce<Record<string, NormalizedFlag[]>>((acc, flag) => {
            flag.opening_links.forEach(({ opening_id }) => {
                if (opening_id) {
                    acc[opening_id] = acc[opening_id] || []
                    acc[opening_id].push(flag)
                }
            })

            return acc
        }, {})

        // Transform flagRecords into Record get index by flag Id
        const result: Record<string, Record<number, number>> = Object.entries(flagRecords).reduce<
            Record<string, Record<number, number>>
        >((acc, [opening_id, flags]) => {
            acc[opening_id] = flags.reduce<Record<number, number>>((flagAcc, flag, index) => {
                flagAcc[flag.id] = index

                return flagAcc
            }, {})

            return acc
        }, {})

        return result
    }
)
