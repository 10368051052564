import { createAction, createSelector } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, SagaReturnType, select, takeEvery } from 'redux-saga/effects'

import {
    PARENT_MAPPING_BASE_HOMES_KEY,
    PARENT_MAPPING_OPTIONS_KEY,
    returnParentMappingKey,
} from './deleteMasterSetOptionsAndBaseHomeDocuments'
import { createMasterSetPlans, fetchProjectDocumentChunks } from '../../../api/projects-api'
import { Coordinate } from '../../../models/activeDrawable'
import { DocumentMapping } from '../../../models/documentMapping'
import { IMasterSetPlanToPost, MASTER_SET_PLAN_ENUMS } from '../../../models/masterSetPlan'
import { Project } from '../../../models/project'
import { PLAN_VIEWS_ENUM } from '../../../shared/constants/plan-views'
import { RootState } from '../../../stores'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { MasterSetPlanRegion } from '../../lib/toolBoxes/2D'
import {
    initializeDocumentChunks,
    initializeProjectDocumentsFromChunks,
    selectDocumentChunksWBuildingID,
    selectDocumentMappingByActiveFloorId,
    updateDocumentMappings,
} from '../../slices/documents'
import { addMasterSetPlanOptionToStore, selectMasterSetPlanToCreate } from '../../slices/masterSetPlan'

export const createMasterSetPlanAction = createAction<{ masterSetPlanOptionType: MASTER_SET_PLAN_ENUMS }>(
    'createMasterSetPlanAction'
)

export const selectProjectActiveChunkState = createSelector(
    ({ IMUP: { documents, project } }: RootState) => {
        return {
            activeDocumentChunkId: documents.activeDocumentChunkId,
            project: project.project,
        }
    },
    (state) => state
)

export function* handleCreateMasterSetPlanAction({ payload }: ReturnType<typeof createMasterSetPlanAction>) {
    const paperManager: PaperManager | null = yield call(managers.get2DManager)

    if (!paperManager) return

    try {
        const { activeDocumentChunkId, project }: { activeDocumentChunkId: number | null; project: Project | null } =
            yield select(selectProjectActiveChunkState)

        if (activeDocumentChunkId === null || project === null) return

        const [masterSetPlanRegionTool] = yield call(paperManager.getTools, [MasterSetPlanRegion.NAME])

        const documentChunks: ReturnType<typeof selectDocumentChunksWBuildingID> = yield select(
            selectDocumentChunksWBuildingID
        )
        const buildingId = documentChunks?.find((d) => d.id === activeDocumentChunkId)?.buildingID

        const activeDocumentMapping: DocumentMapping | null = yield select(selectDocumentMappingByActiveFloorId)

        const { masterSetPlanToCreate, masterSetPlanFormDataState }: ReturnType<typeof selectMasterSetPlanToCreate> =
            yield select(selectMasterSetPlanToCreate)

        const { name: selectedFormName, type: selectedFormType } = masterSetPlanFormDataState

        if (masterSetPlanToCreate.length && buildingId && selectedFormName && selectedFormType) {
            const masterSetOptionsToPost: IMasterSetPlanToPost = {
                plans: masterSetPlanToCreate.map((m) => {
                    return {
                        name: selectedFormName,
                        isOption: payload.masterSetPlanOptionType === MASTER_SET_PLAN_ENUMS.MASTER_SET_PLAN_OPTION,
                        scale: masterSetPlanFormDataState.scale,
                        type: selectedFormType,
                        coordinates: m.coordinates,
                    }
                }),
                building_id: buildingId,
            }

            const responseMasterSetPlanOptions: DocumentMapping[] = yield call(
                createMasterSetPlans,
                project.id,
                activeDocumentChunkId,
                masterSetOptionsToPost
            )

            if (responseMasterSetPlanOptions) {
                yield put(
                    addMasterSetPlanOptionToStore(
                        responseMasterSetPlanOptions.map((mapping) => {
                            const mappingToCreateData = masterSetOptionsToPost.plans.find(
                                (mToCreate) =>
                                    mToCreate.name === mapping.page_name && mToCreate.isOption === mapping.is_option
                            )

                            return {
                                id: mapping.id,
                                document_chunk_id: mapping.document_chunk_id,
                                coordinates: mappingToCreateData!.coordinates,
                                isOption: mapping.is_option,
                                name: mapping.page_name ?? '',
                                type: mapping.type ?? PLAN_VIEWS_ENUM.FLOOR,
                            }
                        })
                    )
                )

                // draw new master set plan options
                yield all(
                    responseMasterSetPlanOptions.map((msOption) => {
                        const mappingToCreateData = masterSetOptionsToPost.plans.find(
                            (mToCreate) =>
                                mToCreate.name === msOption.page_name && mToCreate.isOption === msOption.is_option
                        )

                        return call(
                            masterSetPlanRegionTool.renderBaseHomeOptionPageRegion,
                            mappingToCreateData?.coordinates,
                            msOption.id,
                            msOption.is_option
                        )
                    })
                )

                if (activeDocumentMapping) {
                    const newOptionsAdditionalData = responseMasterSetPlanOptions.reduce(
                        (additionalData, msOption) => {
                            const mappingToCreateData = masterSetOptionsToPost.plans.find(
                                (mToCreate) =>
                                    mToCreate.name === msOption.page_name && mToCreate.isOption === msOption.is_option
                            )

                            additionalData[returnParentMappingKey(msOption)] = [
                                ...additionalData[returnParentMappingKey(msOption)],
                                { id: msOption.id, coordinates: mappingToCreateData?.coordinates },
                            ]

                            return additionalData
                        },
                        {
                            [PARENT_MAPPING_OPTIONS_KEY]:
                                activeDocumentMapping.additional_data[PARENT_MAPPING_OPTIONS_KEY] ??
                                ([] as { id: number; coordinates: Coordinate[] }[]),
                            [PARENT_MAPPING_BASE_HOMES_KEY]:
                                activeDocumentMapping[PARENT_MAPPING_BASE_HOMES_KEY] ??
                                ([] as { id: number; coordinates: Coordinate[] }[]),
                        }
                    )

                    yield put(
                        updateDocumentMappings([
                            {
                                ...activeDocumentMapping,
                                additional_data: {
                                    ...activeDocumentMapping.additional_data,
                                    ...newOptionsAdditionalData,
                                },
                            },
                            ...responseMasterSetPlanOptions,
                        ])
                    )

                    const chunks: SagaReturnType<typeof fetchProjectDocumentChunks> = yield call(
                        fetchProjectDocumentChunks,
                        project.id
                    )

                    yield put(initializeDocumentChunks(chunks))

                    yield put(initializeProjectDocumentsFromChunks(chunks))
                }
            }
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForCreateMasterSetPlanAction(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(createMasterSetPlanAction.type, handleCreateMasterSetPlanAction)
}
