import cloneDeep from 'lodash/cloneDeep'
import Paper from 'paper'

import { IMaterialModificationConflict } from '../../../../models/masterSetPlan'
import { MATERIAL_MODIFICATION_CONFLICT_COLOR } from '../../../../shared/constants/drawable-types'
import store from '../../../../stores'
import { setAltSelectedItems, updateSelectedPaperId } from '../../../slices/2D'
import { updateMaterialModificationConflictToSave } from '../../../slices/masterSetPlan'
import { IMUP2DCoordinatesToUpdate } from '../../../types'

function saveMasterPlanSetModifications(): (coordinatesToUpdate: IMUP2DCoordinatesToUpdate) => void {
    return function saveModification(this: paper.Path, coordinatesToUpdate) {
        const materialModificationConflict = this.data.materialModificationConflict as IMaterialModificationConflict

        const updatedMaterialModificationConflict = cloneDeep(materialModificationConflict)

        updatedMaterialModificationConflict.shapeColor = MATERIAL_MODIFICATION_CONFLICT_COLOR.TOUCHED
        updatedMaterialModificationConflict.coordinates = coordinatesToUpdate.coordinates
        updatedMaterialModificationConflict.cutouts = coordinatesToUpdate.cutouts

        // Set the paper.Path fill or stroke color
        this.fillColor = new Paper.Color(MATERIAL_MODIFICATION_CONFLICT_COLOR.TOUCHED)
        this.strokeColor = new Paper.Color(MATERIAL_MODIFICATION_CONFLICT_COLOR.TOUCHED)

        store.dispatch(updateMaterialModificationConflictToSave([updatedMaterialModificationConflict]))
    }
}

function selectMasterPlanSetModifications(): () => void {
    return function selectModification(this: paper.Path) {
        store.dispatch(updateSelectedPaperId(this.id))
    }
}

function deselectMasterPlanSetModifications(): () => void {
    return function deselectModification(this: paper.Path) {
        store.dispatch(updateSelectedPaperId(null))

        // reset multiple selected items
        store.dispatch(setAltSelectedItems(null))
    }
}

export default function addSelectFunctionalityToModifications(modification: paper.PathItem): void {
    modification.data.onSave = saveMasterPlanSetModifications().bind(modification)
    modification.data.onSelect = selectMasterPlanSetModifications().bind(modification)
    modification.data.onDeselect = deselectMasterPlanSetModifications().bind(modification)
}
