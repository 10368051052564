import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects'

import addMetadataToPath from './data-prep/addMetadataToPath'
import { ActiveFloor } from '../../../models/activeFloor'
import { IToolObject } from '../../../models/tool'
import { DEFAULT_SCALE_FACTOR } from '../../../shared/constants/scales'
import { Color, Label, PathTool, Workspace } from '../../lib/toolBoxes/2D'
import addSelectFunctionalityToToolObjects from '../../lib/utils/functionality-bindings/addSelectFunctionalityToToolObjects'
import { selectDrawableActiveFloor } from '../../slices/documents'
import { changeStrokeWidth } from '../../slices/tools'
import { ItemScale, REGION_ENUMS, TOOL_TYPE_ENUMS } from '../../types'

type DrawToolObjectShapeByTypeYield =
    | CallEffect
    | CallEffect<paper.Color | paper.Path | paper.Raster>
    | PutEffect
    | SelectEffect

type DrawToolObjectShapeByTypeNext = string &
    paper.Color &
    (paper.Raster | null) &
    paper.Path &
    (ActiveFloor | null) &
    paper.Group

export default function* drawToolByType(
    toolObject: Omit<IToolObject, 'id'>,
    colorTool: Color,
    pathTool: PathTool,
    workspaceTool: Workspace,
    lineOpacity: number,
    labelTool?: Label
): Generator<DrawToolObjectShapeByTypeYield, paper.Path, DrawToolObjectShapeByTypeNext> {
    // create color with color tool
    const shapeColor: paper.Color = yield call(colorTool.createColor, toolObject.color)

    // determine if this drawable is inside a region so we can scale it appropriately
    const regionPaths = workspaceTool.getItemsWithCriteria(
        'data',
        (data) => data.shapeType === REGION_ENUMS.TYPE
    ) as paper.Path[]

    let thisRegionGroup: paper.Path | null = null

    for (const regionPath of regionPaths) {
        for (const c of toolObject.coordinates) {
            if (!regionPath.contains(workspaceTool.generatePoint(c))) {
                break
            }
            thisRegionGroup = regionPath
        }
    }
    const activeFloor: ActiveFloor | null = yield select(selectDrawableActiveFloor)

    const scaleFactor = thisRegionGroup?.data?.scale
        ? thisRegionGroup.data.scale
        : activeFloor?.scale_factor ?? DEFAULT_SCALE_FACTOR

    const path: paper.Path = yield call(pathTool.createPath, toolObject.coordinates)
    const raster: paper.Raster | null = yield call(workspaceTool.getPlanRaster)
    let strokeWidthScale = 100

    switch (toolObject.type) {
        case TOOL_TYPE_ENUMS.MEASUREMENT:
            // Add double quotes around keys to parse it correctly
            const toolColorString = toolObject.color.replace(/(\w+):/g, '"$1":')
            const toolColorObj = JSON.parse(toolColorString)

            if (raster) {
                strokeWidthScale = workspaceTool.calculateStrokeWidthBasedOnRaster(raster)
            }

            path.strokeColor = {
                ...shapeColor,
                ...toolColorObj,
            }
            path.strokeWidth = strokeWidthScale * ItemScale.LINE
            path.data.drawing_type = TOOL_TYPE_ENUMS.MEASUREMENT

            if (labelTool) {
                const group: paper.Group = yield call(labelTool.insertLabel, path, toolObject.settings.label!)

                group.visible = true
                group.locked = false
                group.data = path.data
            }

            break
        case TOOL_TYPE_ENUMS.FLOOR_LEVEL_BREAK_LINE:
        default:
            if (toolObject.document_chunk_id) {
                path.data.document_chunk_id = toolObject.document_chunk_id
            }

            shapeColor.alpha = lineOpacity

            if (raster) {
                // Scale the lines based on the size of the image we are working with
                strokeWidthScale = workspaceTool.calculateStrokeWidthBasedOnRaster(raster)
                yield put({ type: changeStrokeWidth.type, payload: strokeWidthScale * ItemScale.LINE })
            }

            path.strokeColor = shapeColor
            path.strokeWidth = strokeWidthScale * ItemScale.LINE
            break
    }

    yield call(addMetadataToPath, path, scaleFactor, toolObject)
    yield call(addSelectFunctionalityToToolObjects, path)

    return path
}
