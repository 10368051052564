import { PayloadAction } from '@reduxjs/toolkit'
import isArray from 'lodash/isArray'
import isNull from 'lodash/isNull'
import { call, select, StrictEffect } from 'redux-saga/effects'

import { ActiveGeometryGroup, GeometricDrawable, selectActiveGeometryGroup } from './../../slices/geometry'
import { findMeshIdBasedOnOpeningId, getThreeDIdFromOpeningIds } from './animateDrawableOnHover'
import {
    cleanupJoistLinesMeshesAndLabels,
    createAndLabelJoistLines,
    filterJoistLinesData,
} from './handleUpdateJoistLinesUpdate'
import { selectAndHighlightRequiredMeshes } from './selectMesh'
import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { Select } from '../../lib/toolBoxes/3D'
import { IMUPMappingsSelector } from '../../slices/common'
import { IBabylonLineWithMetadata, ThreeDToTwoDRecord } from '../../types'

export function* handleToggleActiveDrawable3D({
    payload,
}: PayloadAction<{ drawable_id: number | number[] }>): Generator<
    StrictEffect | (GeometricDrawable | undefined) | number[],
    void,
    (BabylonManager | null) &
        ThreeDToTwoDRecord &
        (string | undefined) &
        (ActiveGeometryGroup | null) &
        (GeometricDrawable | undefined) &
        number[] &
        Select
> {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const threeDToTwoDRecord: ThreeDToTwoDRecord = yield select(IMUPMappingsSelector)

    let resultantThreeDId: string | string[] | undefined

    if (isArray(payload.drawable_id)) {
        resultantThreeDId = yield call(getThreeDIdFromOpeningIds, threeDToTwoDRecord, payload.drawable_id)
    } else {
        resultantThreeDId = yield call(findMeshIdBasedOnOpeningId, threeDToTwoDRecord, payload.drawable_id)
    }

    if (!resultantThreeDId) return

    const activeDrawables: ActiveGeometryGroup | null = yield select(selectActiveGeometryGroup)

    if (isNull(activeDrawables)) return

    const foundDrawable: GeometricDrawable | undefined = yield activeDrawables.openings.find(
        (opening) => opening.id === payload.drawable_id
    )

    // Test if the mesh has been set to active if it has
    // then we need to change it's color to be that of the "main"
    // mesh and more in focus, if it has not then we need to change
    // it back to the lesser focus
    let shouldUseMainHighlight = foundDrawable ? foundDrawable.isActive : false

    if (isArray(payload.drawable_id)) {
        shouldUseMainHighlight = activeDrawables.openings.every((opening) => opening.isActive)
    }

    resultantThreeDId = isArray(resultantThreeDId) ? resultantThreeDId : [resultantThreeDId]

    yield call(selectAndHighlightRequiredMeshes, resultantThreeDId, !!shouldUseMainHighlight, manager)

    const activeDrawableOpeningIds: number[] = yield activeDrawables.openings
        .filter((opeening) => opeening.isActive)
        .map((opening) => opening.id)

    yield call(cleanupJoistLinesMeshesAndLabels, manager)

    const filteredJoistLines: Record<string, IBabylonLineWithMetadata> = yield call(
        filterJoistLinesData,
        activeDrawableOpeningIds
    )

    yield call(createAndLabelJoistLines, filteredJoistLines, manager)
}
