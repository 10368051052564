import { createAction } from '@reduxjs/toolkit'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'

import { DeleteOpeningApiResponse } from '../effects/handleDeleteDrawableGroup'
import { UPDATE_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { deleteOpeningLocation as deleteOpeningLocationAPI, deleteOpenings } from '../../../api/projects-api'
import { OpeningAPI } from '../../../models/activeDrawable'
import { DRAWABLE_TYPES } from '../../../shared/constants/drawable-types'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'
import { getDrawableIdsOfMultiSelectedGroup } from '../../lib/utils/MultiSelect'
import { deleteOpeningLocation, selectDrawableLocations, setDrawableLocations } from '../../slices/2D'
import {
    GeometricDrawable,
    GeometryGroup,
    selectDrawableGeometries,
    selectDrawableGroupsGeometriesHash,
} from '../../slices/geometry'
import { selectProjectId } from '../../slices/project'
import { IMUP2DDrawableLocation } from '../../types'

export const deleteMultiSelectedDrawablesAction = createAction('deleteMultiSelectedDrawables')

export const determineIfJoistArea = (geoDrawable: GeometricDrawable, locationId: number) => {
    const locationInQuestion = geoDrawable.opening_locations.filter((loc) => loc.id === locationId)

    if (locationInQuestion.length === 0) return false

    if (geoDrawable.opening_locations.length > 1) {
        // Joist lines can only be 2 points while joist areas are
        // at least more than 2 points, this is the basic
        // definition of a pen area
        return locationInQuestion[0].coordinates.length > 2
    } else if (locationInQuestion.length === 1) {
        return true
    }
}

export const determineIfWallOpening = (geoDrawable: GeometricDrawable, locationId: number) => {
    const locationInQuestion = geoDrawable.opening_locations.filter((loc) => loc.id === locationId)

    if (locationInQuestion.length === 0) return false

    if (geoDrawable.opening_locations.length > 1) {
        return locationInQuestion[0].additional_data.type !== DRAWABLE_TYPES.KING_STUD
    } else if (locationInQuestion.length === 1) {
        return true
    }
}

export function* handleDeletingOpeningLocationsByIds({
    locationsIdsToDelete,
    openingLocationIdToOpeningAndGroupHash,
    multiSelectedItems,
    imupDrawableLocations,
    idsToExclude,
}: {
    locationsIdsToDelete: number[]
    openingLocationIdToOpeningAndGroupHash: Record<number, { drawableId: number; group: GeometryGroup }>
    multiSelectedItems: paper.Item[]
    imupDrawableLocations: IMUP2DDrawableLocation[]
    idsToExclude: number[]
}) {
    try {
        if (locationsIdsToDelete.length > 0) {
            for (const id of locationsIdsToDelete) {
                const idAndGroup = openingLocationIdToOpeningAndGroupHash[id]

                try {
                    const updatedOpening: OpeningAPI = yield call(deleteOpeningLocationAPI, idAndGroup.drawableId, id)
                    const oldGroup = idAndGroup.group

                    const updatedGeometryGroup: GeometryGroup = {
                        ...oldGroup,
                        openings: oldGroup.openings.map((opening) => {
                            const newOpening = opening.id === updatedOpening?.id ? updatedOpening : opening

                            return { ...newOpening }
                        }),
                    }

                    yield put({
                        type: UPDATE_OPENING_GROUPS_SUCCESS,
                        payload: {
                            openingGroups: { newGroup: updatedGeometryGroup, originalGroup: oldGroup },
                        },
                    })

                    yield call(deleteOpeningLocation, id)

                    const locationItems: paper.Item[] = multiSelectedItems.filter(
                        (item) => item.data.opening_location_id === id
                    )

                    yield all(locationItems.map((loc) => call([loc, 'remove'])))
                } catch (e) {
                    console.error(e)
                }
            }
        }
    } catch (e) {
        console.error(e)
        yield put(setDrawableLocations(imupDrawableLocations.filter((l) => !idsToExclude.includes(l.drawable_id))))
    }
}

export function* handleDeletingOpeningsByIds({
    openingIdsToDelete,
    openingIdToGroupIdHash,
    geometryGroupsHash,
    projectId,
    idsToExclude,
    workspaceTool,
}: {
    openingIdsToDelete: number[]
    openingIdToGroupIdHash: Record<number, number>
    geometryGroupsHash: Record<number, GeometryGroup>
    projectId: number
    idsToExclude: number[]
    workspaceTool: Workspace
}) {
    const { deletedOpenings }: DeleteOpeningApiResponse = yield call(deleteOpenings, projectId, openingIdsToDelete)

    const deletedOpeningItems: paper.Item[] = yield call(workspaceTool.getItemsWithCriteria, 'data', (data) =>
        deletedOpenings.includes(data.drawable_id)
    )

    idsToExclude.push(...deletedOpenings)

    yield all([
        ...Object.values(openingIdToGroupIdHash).map((groupId) => {
            const group: GeometryGroup = geometryGroupsHash[groupId]

            const newGroup = {
                ...group,
                openings: group.openings.filter((op) => !deletedOpenings.includes(op.id)),
            }

            return put({
                type: UPDATE_OPENING_GROUPS_SUCCESS,
                payload: {
                    openingGroups: {
                        newGroup: newGroup,
                        originalGroup: group,
                    },
                },
            })
        }),
    ])

    yield all(deletedOpeningItems.map((loc) => call([loc, 'remove'])))
}

export function* generateIdsToDelete({
    multiSelectedDrawableLocationIds,
    allGeometricDrawables,
    openingIdToGroupIdHash,
    geometryGroupsHash,
    openingLocationIdToOpeningAndGroupHash,
}: {
    multiSelectedDrawableLocationIds: number[]
    allGeometricDrawables: GeometricDrawable[]
    openingIdToGroupIdHash: Record<number, number>
    geometryGroupsHash: Record<number, GeometryGroup>
    openingLocationIdToOpeningAndGroupHash: Record<number, { drawableId: number; group: GeometryGroup }>
}) {
    return yield multiSelectedDrawableLocationIds.reduce(
        (idObjects, locationId) => {
            const geoDrawable = allGeometricDrawables.find((geoDrawable) => {
                return geoDrawable.opening_locations.map((loc) => loc.id).includes(locationId)
            })

            if (!geoDrawable) return idObjects

            openingIdToGroupIdHash[geoDrawable.id] = geoDrawable.opening_group_id

            const groupType = geometryGroupsHash[geoDrawable.opening_group_id].type

            switch (groupType) {
                case DRAWABLE_TYPES.WALL:
                    if (determineIfWallOpening(geoDrawable, locationId)) {
                        idObjects.openingIdsToDelete.push(geoDrawable.id)
                    } else {
                        openingLocationIdToOpeningAndGroupHash[locationId] = {
                            group: geometryGroupsHash[geoDrawable.opening_group_id],
                            drawableId: geoDrawable.id,
                        }
                        idObjects.locationsIdsToDelete.push(locationId)
                    }
                    break
                case DRAWABLE_TYPES.FLOOR_SYSTEM:
                case DRAWABLE_TYPES.ROOF_SYSTEM:
                    if (determineIfJoistArea(geoDrawable, locationId)) {
                        idObjects.openingIdsToDelete.push(geoDrawable.id)
                    }
                    break
                default:
                    if (geoDrawable.opening_locations.length === 1) {
                        idObjects.openingIdsToDelete.push(geoDrawable.id)
                    }
                    break
            }

            return idObjects
        },
        {
            openingIdsToDelete: [] as number[],
            locationsIdsToDelete: [] as number[],
        }
    )
}

export function* handleDeleteMultiSelectedDrawables() {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const projectId: number | null = yield select(selectProjectId)

    if (!projectId) return

    const [selectTool, workspaceTool]: [Select, Workspace] = yield call(manager.getTools, [Select.NAME, Workspace.NAME])

    const multiSelectedItems: paper.Item[] = yield call(selectTool.getMultiSelectedItems)

    const multiSelectedDrawableLocationIds: number[] = yield call(
        getDrawableIdsOfMultiSelectedGroup,
        multiSelectedItems
    )

    if (multiSelectedDrawableLocationIds.length === 0) return

    const allGeometricDrawables: GeometricDrawable[] = yield select(selectDrawableGeometries)

    const geometricDrawablesPresentOnPage: GeometricDrawable[] = yield allGeometricDrawables.reduce(
        (drawables, geoDrawable) => {
            const geoLocationIds = geoDrawable.opening_locations.map((loc) => loc.id)
            const isAnySelectedLocationInLocation = multiSelectedDrawableLocationIds.reduce((isPresent, id) => {
                return isPresent || geoLocationIds.includes(id)
            }, false)

            if (isAnySelectedLocationInLocation) drawables.push(geoDrawable)

            return drawables
        },
        [] as GeometricDrawable[]
    )

    const imupDrawableLocations: IMUP2DDrawableLocation[] = yield select(selectDrawableLocations)

    if (geometricDrawablesPresentOnPage.length === 0) return

    const geometryGroupsHash: Record<string, GeometryGroup> = yield select(selectDrawableGroupsGeometriesHash)

    const openingIdToGroupIdHash: Record<number, number> = {}

    const openingLocationIdToOpeningAndGroupHash: Record<number, { group: GeometryGroup; drawableId: number }> = {}

    const {
        openingIdsToDelete,
        locationsIdsToDelete,
    }: { openingIdsToDelete: number[]; locationsIdsToDelete: number[] } = yield call(generateIdsToDelete, {
        multiSelectedDrawableLocationIds,
        allGeometricDrawables,
        openingIdToGroupIdHash,
        geometryGroupsHash,
        openingLocationIdToOpeningAndGroupHash,
    })

    const idsToExclude: number[] = [...locationsIdsToDelete]

    if (openingIdsToDelete.length > 0) {
        yield call(handleDeletingOpeningsByIds, {
            openingIdsToDelete,
            openingIdToGroupIdHash,
            geometryGroupsHash,
            workspaceTool,
            projectId,
            idsToExclude,
        })
    }

    yield call(handleDeletingOpeningLocationsByIds, {
        locationsIdsToDelete,
        openingLocationIdToOpeningAndGroupHash,
        multiSelectedItems,
        imupDrawableLocations,
        idsToExclude,
    })

    yield call(selectTool.clearAllMultiSelections)

    yield put(
        setDrawableLocations(
            imupDrawableLocations.filter(
                (l) => !idsToExclude.includes(l.drawable_id) && !idsToExclude.includes(l.opening_location_id)
            )
        )
    )
}

export function* watchForDeleteMultiSelectedDrawables() {
    yield takeEvery(deleteMultiSelectedDrawablesAction, handleDeleteMultiSelectedDrawables)
}
