import { UiSchema } from '@rjsf/core'
import { JSONSchema7, JSONSchema7Definition } from 'json-schema'

import { calculateAreaFromCoords } from '../calculations/areaCalculation/calculateAreaFromCoords'
import { convertScaleFactorLabelEnumToDecimal } from '../calculations/scaleConversion/scaleConversion'
import { TOOL_TYPE_ENUMS } from '../../imup/types'
import { DrawableLocation } from '../../models/activeDrawable'
import { AzureFeatureFlag } from '../../models/azureFeatureFlags'
import { Region } from '../../models/region'
import { AzureFeatureFlagIds } from '../../shared/constants/azure-feature-flags'
import { DRAWABLE_TYPES } from '../../shared/constants/drawable-types'
import { FORM_ERROR_MESSAGES } from '../../shared/constants/error-messages'
import { isAzureFeatureFlagEnabled } from '../../shared/services/azure-feature-flag-services/azure-feature-flag-services'

export const fieldsToNull = (newSettings, fields: string[]) => {
    const settingsCopy = { ...newSettings }

    fields.forEach((field) => {
        settingsCopy[field] = field in settingsCopy ? settingsCopy[field] : null
    })

    return settingsCopy
}

export const calculateTotalArea = (
    drawableLocations: DrawableLocation[],
    regions: Region[],
    scaleFactor: number,
    pdfScale: number,
    xCalibrationFactor: number,
    yCalibrationFactor: number,
    dpi: number | null
): number => {
    return drawableLocations
        .map(({ coordinates, region_id }) => {
            if (region_id) {
                const region = regions.find((region) => region.id === region_id)
                const regionScale = convertScaleFactorLabelEnumToDecimal(region?.scale as string)

                return {
                    coords: coordinates.flatMap((coord) => coord),
                    scaleFactor: regionScale,
                }
            }

            return {
                coords: coordinates.flatMap((coord) => coord),
                scaleFactor,
            }
        })
        .reduce(
            (totalArea, { coords, scaleFactor }) =>
                totalArea +
                calculateAreaFromCoords({
                    coords,
                    scaleFactor,
                    pdfScale,
                    xCalibrationFactor,
                    yCalibrationFactor,
                    dpi,
                }),
            0
        )
}

const convertErrorMessage = (message: string): string => {
    if (message.includes('pattern')) {
        return FORM_ERROR_MESSAGES.FULL_NUMBER
    }

    return message
}

export const renderErrors = (errors: string[], fieldName: string) => {
    return (
        <ul>
            {errors.map((error, i) => {
                return (
                    <li key={i}>
                        <small className="m-0 text-danger" key={`error_${error}_${i}_${fieldName}`}>
                            {convertErrorMessage(error)}
                        </small>
                    </li>
                )
            })}
        </ul>
    )
}

/**
 * Checks if the provided field value is considered "other,"
 * meaning it does not exist in the enum of the given field definition.
 *
 * @param fieldDefinition - The JSON schema definition that may include an enum.
 * @param fieldValue - The value to check against the enum in the field definition.
 *
 * @returns True if the field value is not included in the enum, false otherwise.
 */
export const isValueOther = (
    fieldDefinition: JSONSchema7Definition | undefined,
    fieldValue?: string | number | boolean | null
): boolean => {
    return (
        !!fieldValue &&
        !!fieldDefinition &&
        typeof fieldDefinition === 'object' &&
        'enum' in fieldDefinition &&
        !fieldDefinition.enum?.includes(fieldValue)
    )
}

// TODO: move to general utils function
const isAdvancedSettingsEnabled = (
    type: DRAWABLE_TYPES | TOOL_TYPE_ENUMS,
    azureFeatureFlags: AzureFeatureFlag[]
): boolean => {
    switch (type) {
        case DRAWABLE_TYPES.WALL:
        case DRAWABLE_TYPES.EAVE_LENGTH:
        case DRAWABLE_TYPES.GABLE_LENGTH:
        case DRAWABLE_TYPES.DROPPED_BEAM:
        case DRAWABLE_TYPES.FLUSH_BEAM:
        case DRAWABLE_TYPES.RIDGE_BEAM:
        case DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM:
        case DRAWABLE_TYPES.HEADER:
        case DRAWABLE_TYPES.POST:
        case DRAWABLE_TYPES.FLOOR_SYSTEM:
        case DRAWABLE_TYPES.LEDGER:
        case DRAWABLE_TYPES.FRAMING_ROOFING:
        case DRAWABLE_TYPES.ROOF_SYSTEM: {
            return true
        }
        case DRAWABLE_TYPES.BLOCKING: {
            return isAzureFeatureFlagEnabled(azureFeatureFlags, AzureFeatureFlagIds.new_blocking_tool)
        }
        case DRAWABLE_TYPES.STEEL_BEAM_PLATE: {
            return isAzureFeatureFlagEnabled(azureFeatureFlags, AzureFeatureFlagIds.steel_beam_plate)
        }
        default:
            return false
    }
}

const addConditionalProperties = (uischema: UiSchema, schema: JSONSchema7): void => {
    if (schema.allOf) {
        schema.allOf.forEach((condition) => {
            handleThenCondition(uischema, condition)
            handleNestedConditions(uischema, condition)
        })
    }
}
const handleThenCondition = (uischema: UiSchema, condition: any): void => {
    if (condition.then) {
        for (const prop in condition.then.properties) {
            if (!uischema[prop]) {
                uischema[prop] = {}
            }
        }
    }
}
const handleNestedConditions = (uischema: UiSchema, condition: any): void => {
    if (condition.if) {
        addConditionalProperties(uischema, condition.if)
    }
    if (condition.then) {
        addConditionalProperties(uischema, condition.then)
    }
}

interface Condition {
    properties?: Record<string, any>
    not?: Condition
    allOf?: Condition[]
    if?: Condition
    then?: {
        properties?: Record<string, any>
        allOf?: Condition[]
    }
    else?: {
        properties?: Record<string, any>
        allOf?: Condition[]
    }
}

interface Schema {
    allOf?: Condition[]
    properties?: Record<string, any>
}

const evaluateCondition = (condition: Condition, data: Record<string, any>): boolean => {
    if (!condition || typeof condition !== 'object') return true
    if (condition.properties && !evaluateProperties(condition.properties, data)) {
        return false
    }
    if (condition.not && !evaluateNotCondition(condition.not, data)) {
        return false
    }
    if (condition.allOf && !condition.allOf.every((subCondition) => evaluateCondition(subCondition, data))) {
        return false
    }

    return true
}

const evaluateProperties = (properties: Record<string, any>, data: Record<string, any>): boolean => {
    for (const key in properties) {
        if (!evaluateProperty(properties[key], data[key])) {
            return false
        }
    }

    return true
}

const evaluateProperty = (property: any, value: any): boolean => {
    if (property.const !== undefined && value !== property.const) {
        return false
    }
    if (property.enum !== undefined && !property.enum.includes(value)) {
        return false
    }
    if (property.not !== undefined && !evaluateNot(property.not, value)) {
        return false
    }

    return true
}

const evaluateNot = (not: any, value: any): boolean => {
    if (not.const !== undefined && value === not.const) {
        return false
    }
    if (not.enum !== undefined && not.enum.includes(value)) {
        return false
    }

    return true
}

const evaluateNotCondition = (notCondition: Condition, data: Record<string, any>): boolean => {
    return !evaluateCondition(notCondition, data)
}

const evaluateConditionalSchema = (
    conditionalSchema: Condition,
    data: Record<string, any>,
    resultRecords: Record<string, boolean>
): void => {
    if (conditionalSchema.if && evaluateCondition(conditionalSchema.if, data)) {
        if (conditionalSchema.then) {
            if (conditionalSchema.then.properties) {
                for (const key in conditionalSchema.then.properties) {
                    if (conditionalSchema.then.properties.hasOwnProperty(key)) {
                        resultRecords[key] = true
                    }
                }
            }
            if (conditionalSchema.then.allOf) {
                for (const subSchema of conditionalSchema.then.allOf) {
                    evaluateConditionalSchema(subSchema, data, resultRecords)
                }
            }
        }
    } else if (conditionalSchema.else) {
        if (conditionalSchema.else.properties) {
            for (const key in conditionalSchema.else.properties) {
                if (conditionalSchema.else.properties.hasOwnProperty(key)) {
                    resultRecords[key] = true
                }
            }
        }
        if (conditionalSchema.else.allOf) {
            for (const subSchema of conditionalSchema.else.allOf) {
                evaluateConditionalSchema(subSchema, data, resultRecords)
            }
        }
    }
}

const getVisibleProperties = (formData: Record<string, any>, schema: Schema): Record<string, boolean> => {
    const results: Record<string, boolean> = {}

    // Evaluate allOf conditions
    if (schema.allOf) {
        for (const conditionalSchema of schema.allOf) {
            evaluateConditionalSchema(conditionalSchema, formData, results)
        }
    }

    // Include properties that are always visible
    if (schema.properties) {
        for (const key in schema.properties) {
            if (!results.hasOwnProperty(key)) {
                results[key] = true // Assume visible if not explicitly set otherwise
            }
        }
    }

    return results
}

export const advanced_settings_helper = {
    getVisibleProperties,
    isAdvancedSettingsEnabled,
    addConditionalProperties,
}
