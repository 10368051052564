import { createAction } from '@reduxjs/toolkit'
import { delay, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'

import { TOASTER_TIMER } from '../../../components/projects/project-settings/utils/constants'
import { Building } from '../../../models/projectBuilding'
import { selectActiveBuilding, selectBuildings, setBuildings } from '../../slices/buildings'
import { setLoading } from '../../slices/common'

export const applyToAllBuildingAction = createAction('applyToAllBuilding')

export function* handleApplyToAllBuilding() {
    const buildings: Building[] = yield select(selectBuildings)
    const activeBuilding: Building = yield select(selectActiveBuilding)

    yield put(setLoading(true))

    // added temporary empty lumber settings in case it doesn't exist
    // this will allow copy settings works
    const buildingsWithLumberSettings: Building[] = buildings.map((building) => {
        return {
            ...building,
            settings: {
                ...building.settings,
                lumber_settings: building.settings?.lumber_settings || {},
                eaves_and_gables_settings: building.settings?.eaves_and_gables_settings || {},
            },
        }
    })

    const updatedBuildings = buildingsWithLumberSettings.map((otherBuilding) => {
        if (otherBuilding.id !== activeBuilding.id) {
            let otherBuildingCopy: Building = { ...otherBuilding }

            Object.keys(otherBuildingCopy.settings).forEach((buildingSetting) => {
                if (otherBuildingCopy.settings[buildingSetting] !== null) {
                    for (const setting in activeBuilding.settings[buildingSetting]) {
                        if (setting === 'rcm_id') {
                            continue
                        }
                        otherBuildingCopy = {
                            ...otherBuildingCopy,
                            settings: {
                                ...otherBuildingCopy.settings,
                                [buildingSetting]: {
                                    ...otherBuildingCopy.settings[buildingSetting],
                                    [setting]: activeBuilding.settings[buildingSetting][setting],
                                },
                            },
                        }
                    }
                }
            })

            return otherBuildingCopy
        }

        return otherBuilding
    })

    yield put(setBuildings({ buildings: updatedBuildings }))

    // just visual effect that we are updating buildings
    yield delay(TOASTER_TIMER)

    yield put(setLoading(false))
}

export function* watchForApplyToAllBuilding(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(applyToAllBuildingAction.type, handleApplyToAllBuilding)
}
