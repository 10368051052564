import { ActionReducerMapBuilder, createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { NO_AI_CLASSIFICATION_DATA } from '../utils/constants'
import { checkNameByType } from '../utils/helpers'
import { gotMappings, NormalizedDocumentChunk } from './documents'
import { initialRegionFormState, RegionFormFields } from './region'
import { SET_ACTIVE_FLOOR } from '../../actions/drawable'
import { ActiveFloor } from '../../models/activeFloor'
import { DocumentMapping } from '../../models/documentMapping'
import { Project } from '../../models/project'
import { ProjectDocument } from '../../models/projectDocument'
import { Region } from '../../models/region'
import { PLAN_VIEWS_ENUM } from '../../shared/constants/plan-views'
import { RootState } from '../../stores'

export type Mapping = Omit<ActiveFloor, 'project_document' | 'document_chunk'>

export const setActiveMapping = createAction<ActiveFloor>(SET_ACTIVE_FLOOR)

export interface BasicFloorMapping {
    color: { r: number; g: number; b: number; hex: string }
    hash: string
    name: string
    is_floor: boolean
    prefix: string | null
    short_name: string
    page_name: string
    type: PLAN_VIEWS_ENUM
}

export type MappingsState = {
    mappings: Mapping[] | null
    allPossibleFloorMappings: BasicFloorMapping[] | null
    activeMappingId: number | null
    mappingFormState: RegionFormFields
}

export const initialMappingsState: MappingsState = {
    mappings: null,
    allPossibleFloorMappings: null,
    activeMappingId: null,
    mappingFormState: initialRegionFormState,
}

function processFloorsIntoMappings(
    state: MappingsState,
    action: PayloadAction<{ floors: ActiveFloor[]; project: Project }>
): void {
    const floors = action.payload.floors

    state.mappings = floors.map((floor) => {
        return { ...floor, document_chunk: undefined, project_document: undefined }
    })
}

function processActiveMapping(state: MappingsState, action: PayloadAction<ActiveFloor>) {
    state.activeMappingId = action.payload.id
}

const extraReducers = (builder: ActionReducerMapBuilder<MappingsState>): void => {
    builder.addCase(gotMappings, processFloorsIntoMappings)
    builder.addCase(setActiveMapping, processActiveMapping)
}

const mappingsSlice = createSlice({
    name: 'mappings',
    initialState: initialMappingsState,
    reducers: {
        setAllPossibleMappings(state: MappingsState, { payload }: PayloadAction<BasicFloorMapping[] | null>) {
            state.allPossibleFloorMappings = payload
                ? payload.map((floor) => {
                      return {
                          ...floor,
                          document_chunk: undefined,
                          project_document: undefined,
                          page_name: floor.short_name,
                      }
                  })
                : null
        },
        updateActiveMapping: (state: MappingsState, { payload }: PayloadAction<DocumentMapping>) => {
            return {
                ...state,
                activeMappingId: payload.id,
                mappingFormState: {
                    type: payload.type,
                    view: payload.additional_data.view,
                    scale: payload.scale_factor, //payload.additional_data.scale,
                    name: checkNameByType(payload.type, payload.page_name || '') ? (payload.page_name as string) : '',
                },
            }
        },
        updateMappingFormState: (state: MappingsState, { payload }: PayloadAction<Partial<RegionFormFields>>) => {
            return {
                ...state,
                mappingFormState: {
                    ...state.mappingFormState,
                    ...payload,
                    name: checkNameByType(payload.type!, payload.name || '') ? (payload.name as string) : '',
                },
            }
        },
        resetMappingFormState: (state: MappingsState) => {
            return { ...state, activeMappingId: null, mappingFormState: initialRegionFormState }
        },
    },
    extraReducers,
})

export const selectAllMappings = createSelector(
    (state: RootState) => state.IMUP.mappings.mappings,
    (mappings) => mappings
)

const reconstructActiveFloorFromMapping = (
    mapping: Mapping,
    projectDocuments: ProjectDocument[],
    documentChunksWithRegions: (NormalizedDocumentChunk & { regions: Region[] })[]
): ActiveFloor => {
    const documentChunkWRegion = documentChunksWithRegions.find((doc) => doc.mappingID === mapping.id)
    const projDoc = projectDocuments.find((doc) => doc.id === documentChunkWRegion?.project_document_id)

    return {
        ...mapping,
        project_document: projDoc!,
        document_chunk: {
            ...documentChunkWRegion!,
            project_document: projDoc!,
            regions: documentChunkWRegion?.regions ?? [],
        },
    }
}

export const selectAllFloors = createSelector(
    (state: RootState) => {
        const regions = state.IMUP.region.regions
        const documentChunks = state.IMUP.documents.documentChunks?.map((docChunk) => {
            return { ...docChunk, regions: regions.filter((region) => region.document_chunk_id === docChunk.id) }
        })
        const projectDocuments = state.IMUP.documents.projectDocuments
        const mappings = state.IMUP.mappings.mappings

        if (documentChunks && projectDocuments && mappings) {
            return mappings.map((mapping) =>
                reconstructActiveFloorFromMapping(mapping, projectDocuments, documentChunks)
            )
        }

        return []
    },
    (floors) => (floors ? (floors as ActiveFloor[]) : null)
)

export const selectAllPossibleFloors = createSelector(
    (state: RootState) => {
        return {
            allPossibleFloorMappings: state.IMUP.mappings.allPossibleFloorMappings,
            floorMappings: state.IMUP.mappings.mappings,
        }
    },
    (state) => state
)

export const selectIsRunAIForAllProject = createSelector(
    (state: RootState) => state.IMUP.mappings.mappings,
    (mappings) =>
        false ||
        (mappings &&
            mappings.every(
                (m) =>
                    m.ai_classification &&
                    m.ai_classification?.id !== NO_AI_CLASSIFICATION_DATA.id &&
                    !m.page_name.startsWith('page')
            ))
)

export const { setAllPossibleMappings, updateMappingFormState, updateActiveMapping, resetMappingFormState } =
    mappingsSlice.actions
export default mappingsSlice
