import { createAction } from '@reduxjs/toolkit'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { handleCloseMaterialForm } from '../effects/handleCloseMaterialForm'
import { generateMeasurementsToUpdate, selectProject, selectScaleFactor } from './createDrawableLocation'
import { UPDATE_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { CreateBulkAIOpeningsResponse } from '../../../api/api-helper'
import { createBulkAIOpenings } from '../../../api/projects-api'
import { OpeningGroup, OpeningGroupAPI } from '../../../models/activeDrawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { Project } from '../../../models/project'
import { Building } from '../../../models/projectBuilding'
import { DRAWABLE_TYPES } from '../../../shared/constants/drawable-types'
import { convertScaleEnumToString } from '../../../utils/calculations/scaleConversion/scaleConversion'
import { defineLocationRegion } from '../../../utils/coordinates/defineLocationRegion'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'
import { deleteAISuggestions, getSelectedMaterial, selectedAiSuggestionIds } from '../../slices/2D'
import { selectActiveBuildingFromActiveFloor } from '../../slices/buildings'
import { selectActiveChunkScalesAndDPI, selectDrawableActiveFloor } from '../../slices/documents'
import { formError } from '../../slices/forms'
import { selectAllRegions } from '../../slices/region'
import { AutomatedObjectToCreate, BulkAIOpeningsCreationData } from '../../types'

export const submitAIMaterialsForm = createAction<{ formData: OpeningGroup['settings'] }>('submitAIMaterialsForm')

export function* handleAIMaterialsFormSubmit({ payload }: ReturnType<typeof submitAIMaterialsForm>) {
    try {
        const selectedAISuggestionIds = yield select(selectedAiSuggestionIds)
        const type: DRAWABLE_TYPES = yield select(getSelectedMaterial)
        const project: Project = yield select(selectProject)
        const activeFloor: ActiveFloor = yield select(selectDrawableActiveFloor)
        const scaleFactor: number = yield select(selectScaleFactor)
        const regions = yield select(selectAllRegions)
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const [workspaceTool, selectTool]: [Workspace, Select] = yield call(manager.getTools, [
            Workspace.NAME,
            Select.NAME,
        ])

        const aiItems: paper.Path[] = yield call(workspaceTool.getItemsWithCriteria, 'data', function (data: any) {
            return selectedAISuggestionIds.map((id) => id).includes(data?.aiSuggestion?.id)
        })

        const {
            dpi,
            xCalibrationFactor,
            yCalibrationFactor,
            pdfScale,
        }: {
            dpi: number | null
            xCalibrationFactor: number
            yCalibrationFactor: number
            pdfScale: number
        } = yield select(selectActiveChunkScalesAndDPI)

        const AIObjects: AutomatedObjectToCreate[] = []

        aiItems.forEach((item) => {
            const dataToMeasure = {
                path: item,
                settingsAndType: { type: type, settings: payload.formData },
                scaleFactor,
                pdfScale,
                xCalibrationFactor,
                yCalibrationFactor,
                coordinates: item.data.aiSuggestion.coordinates,
                dpi,
            }

            const region_id = defineLocationRegion(item, regions, workspaceTool)
            const additional_data: Record<string, any> = {}

            if (region_id) {
                const regionPath = workspaceTool.getRegionItemByRegionId(region_id)

                additional_data.scale_factor = convertScaleEnumToString(regionPath.data.scale)
            } else {
                additional_data.scale_factor = convertScaleEnumToString(activeFloor.scale_factor)
            }

            const AIObject: AutomatedObjectToCreate = {
                id: item.data.aiSuggestion.id,
                document_chunk_id: item.data.aiSuggestion.document_mapping.document_chunk_id,
                additional_data: additional_data,
                coordinates: item.data.aiSuggestion.coordinates,
                measurements: generateMeasurementsToUpdate(dataToMeasure),
                region_id: region_id,
            }

            AIObjects.push(AIObject)
        })

        const activeBuilding: Building | undefined = yield select(selectActiveBuildingFromActiveFloor)

        const createBulkAIRequest: BulkAIOpeningsCreationData = {
            type: type,
            settings: payload.formData,
            automated_objects: [...AIObjects],
            rcm_building_id: activeBuilding?.rcm_id ?? '',
        }

        const bulkAIOpeningsResponse: CreateBulkAIOpeningsResponse = yield call(
            createBulkAIOpenings,
            project.id,
            createBulkAIRequest
        )

        const drawableGroup: OpeningGroupAPI = {
            ...bulkAIOpeningsResponse.group,
        }

        const newActiveDrawableGroup = {
            ...drawableGroup,
            openings: bulkAIOpeningsResponse.group.openings,
            drawables: bulkAIOpeningsResponse.group.openings,
            settings: bulkAIOpeningsResponse.group.settings,
        }

        yield all([
            put({
                type: UPDATE_OPENING_GROUPS_SUCCESS,
                payload: {
                    openingGroups: {
                        newGroup: newActiveDrawableGroup,
                        originalGroup: drawableGroup,
                    },
                },
            }),
        ])

        for (const item of aiItems) {
            yield call(item.remove.bind(item))
        }

        yield call(selectTool.removeHandles)

        yield put(deleteAISuggestions(selectedAISuggestionIds))
        yield fork(handleCloseMaterialForm)
    } catch (error) {
        yield put(formError((error as any).message))
    }
}

export function* watchForAIMaterialFormSubmit() {
    yield takeLatest([submitAIMaterialsForm.type], handleAIMaterialsFormSubmit)
}
