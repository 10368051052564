import { setActiveDrawableGroupFromID } from '../../../../actions/drawable'
import { DRAWABLE_TYPES, DRAWING_TYPES } from '../../../../shared/constants/drawable-types'
import store from '../../../../stores'
import {
    bulkUpdateOpeningLocationCoordinatesAction,
    BulkUpdateOpeningLocationCoordinatesPayload,
} from '../../../sagas/2D/bulkUpdateOpeningLocationCoordinates'
import { editingActive, selectedPaperItem, setAltSelectedItems, updateSelectedCoordinates } from '../../../slices/2D'
import { IMUP2DCoordinatesToUpdate } from '../../../types'

function save2DDrawable(): (coordinatesToUpdate: IMUP2DCoordinatesToUpdate) => void {
    return function saveDrawable(this: paper.Path, coordinatesToUpdate) {
        store.dispatch(updateSelectedCoordinates(coordinatesToUpdate))
    }
}

function multiSelectSave2DDrawable(): (payload: BulkUpdateOpeningLocationCoordinatesPayload) => void {
    return function saveDrawable(coordinatesToUpdate) {
        store.dispatch(bulkUpdateOpeningLocationCoordinatesAction(coordinatesToUpdate))
    }
}

function select2DDrawable(): () => void {
    return function selectDrawable(this: paper.Path) {
        store.dispatch(
            setActiveDrawableGroupFromID(
                this.id,
                this.data.drawable_id,
                this.data.opening_group_id,
                this.data.opening_location_id
            )
        )
        store.dispatch(editingActive(false))
    }
}

function deselect2DDrawable(): () => void {
    return function deselectDrawable(this: paper.Path) {
        store.dispatch(
            selectedPaperItem({
                selectedItem: null,
                activeDrawableId: null,
                activeOpeningLocationId: null,
                openingGroupId: null,
            })
        )

        // reset multiple selected items
        store.dispatch(setAltSelectedItems(null))
    }
}

function determineIfMultiSelectable(drawable: paper.PathItem) {
    if (drawable.data.drawing_type === DRAWABLE_TYPES.WALL) {
        return drawable.data.shapeType !== DRAWING_TYPES.POINT
    }

    if (
        drawable.data.drawing_type === DRAWABLE_TYPES.FLOOR_SYSTEM ||
        drawable.data.drawing_type === DRAWABLE_TYPES.ROOF_SYSTEM
    ) {
        return drawable.data.shapeType !== DRAWING_TYPES.SECTION
    }

    return true
}

export default function addSelectFunctionalityToDrawable(drawable: paper.PathItem): void {
    drawable.data.isMultiSelectable = determineIfMultiSelectable(drawable)
    drawable.data.onSave = save2DDrawable().bind(drawable)
    drawable.data.onSelect = select2DDrawable().bind(drawable)
    drawable.data.onDeselect = deselect2DDrawable().bind(drawable)
    drawable.data.onMultiSelectSave = multiSelectSave2DDrawable()
}
