import { all, call, put, select } from 'redux-saga/effects'

import { updateSingleDocumentMapping } from '../effects/updateSingleDocumentMapping'
import { convertOpeningGroupsToDrawableLocations } from './data-prep/convertOpeningsToDrawableLocations'
import { getMeasurementLabelForToolObject } from './handleCreateMeasurements'
import { FETCH_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { fetchOpeningGroupsByProjectId, updateDocumentMapping } from '../../../api/takeoff-api'
import { OpeningGroupAPI } from '../../../models/activeDrawable'
import { DocumentChunk } from '../../../models/documentChunk'
import { DocumentMapping } from '../../../models/documentMapping'
import { IMasterSetPlanOption } from '../../../models/masterSetPlan'
import { IToolObject } from '../../../models/tool'
import { preparedDrawablesSuccess } from '../../slices/2D'
import {
    resetDocumentChunkCalibration,
    selectActiveDocumentChunk,
    selectDocumentMappings,
    updateDocumentMappings,
} from '../../slices/documents'
import { selectMasterSetPlanOptions, updateMasterSetPlanOptions } from '../../slices/masterSetPlan'
import { selectProjectId } from '../../slices/project'
import { selectMeasurementToolObjects, updateToolObjectByToolId } from '../../slices/tools'
import { IMUP2DDrawableLocation } from '../../types'

export function* updateLabelForToolObjectInStore(
    toolObject: IToolObject,
    chunk: DocumentChunk,
    mapping: DocumentMapping
) {
    const newLabel: string = yield call(getMeasurementLabelForToolObject, toolObject, chunk, mapping)

    yield put(
        updateToolObjectByToolId({
            ...toolObject,
            settings: {
                ...toolObject.settings,
                label: newLabel,
            },
        })
    )
}

export function* handleUpdateMeasurementToolObjectLabels(chunk: DocumentChunk, mapping: DocumentMapping) {
    const toolObjects: IToolObject[] = yield select(selectMeasurementToolObjects)

    yield all(
        toolObjects.map((object) => {
            if (object.document_chunk_id === chunk.id) {
                return call(updateLabelForToolObjectInStore, object, chunk, mapping)
            }
        })
    )
}

export function* handleUpdateSingleDocumentMapping2D({ payload }: ReturnType<typeof updateSingleDocumentMapping>) {
    try {
        const projectId: number = yield select(selectProjectId)
        const activeDocumentChunk: DocumentChunk | null = yield select(selectActiveDocumentChunk)
        const documentMappings: DocumentMapping[] = yield select(selectDocumentMappings)
        const masterSetOptions: IMasterSetPlanOption[] = yield select(selectMasterSetPlanOptions)

        yield call(updateDocumentMapping, projectId, {
            ...payload,
            updated_at: undefined,
            created_at: undefined,
            deleted_at: undefined,
        } as DocumentMapping)

        // Update the mapping in the store
        yield put(updateDocumentMappings([payload]))

        yield put(
            updateMasterSetPlanOptions(
                masterSetOptions.map((option) => {
                    if (option.id === payload.id) {
                        return {
                            ...option,
                            name: payload.page_name ?? '',
                            scale: payload.scale_factor,
                            type: payload.type,
                        }
                    }

                    return option
                })
            )
        )

        const foundDocumentMapping = yield documentMappings.find((documentMapping) => documentMapping.id === payload.id)

        if (activeDocumentChunk && foundDocumentMapping && foundDocumentMapping.scale_factor !== payload.scale_factor) {
            yield put(resetDocumentChunkCalibration({ documentChunkId: activeDocumentChunk.id }))
        }

        // Fetch opening groups from api
        const openingGroups: OpeningGroupAPI[] = yield call(fetchOpeningGroupsByProjectId, projectId)

        // Original IMUP: pass api response to original IMUP reducer
        yield put({ type: FETCH_OPENING_GROUPS_SUCCESS, payload: openingGroups })

        // Convert opening groups to drawable locations with appropriate metadata
        const drawableLocations: IMUP2DDrawableLocation[] = yield call(
            convertOpeningGroupsToDrawableLocations,
            openingGroups
        )

        // Retrieve the unique set of document chunk IDs for all locations
        const documentChunkIds: number[] = [...new Set(drawableLocations.map((location) => location.document_chunk_id))]

        // Provide drawable locations and unique document chunk IDs to the store
        yield put(preparedDrawablesSuccess({ drawableLocations, documentChunkIds }))

        if (activeDocumentChunk) {
            // Update all markup context objects (Updating measurement labels)
            yield call(handleUpdateMeasurementToolObjectLabels, activeDocumentChunk, payload)
        }
    } catch (error) {
        yield call(console.error, error)
    }
}
