import { Mesh } from '@babylonjs/core/Meshes/mesh'
import isNull from 'lodash/isNull'
import { call, CallEffect, put, PutEffect, select, SelectEffect } from 'redux-saga/effects'

import { Opening_location, OpeningGroup } from '../../../../models/activeDrawable'
import { geometriesSelector, joistLinesDataSelector, State3D, updateJoistLines } from '../../../slices/3D'
import { GeometryGroup } from '../../../slices/geometry'
import {
    IBabylonLineWithMetadata,
    IGeometry,
    IVector,
    JOIST_LINE_DRAWABLE_NAME,
    JOIST_LINES_SUFFIX,
    JoistLinesRawData,
} from '../../../types'

export function isOpeningLocationAJoistLine(location: Opening_location) {
    return location.additional_data && location.additional_data.joist_length && location.coordinates.length === 2
}

export function* calculateJoistDataFromDrawableGroups(
    drawableGroups: GeometryGroup[] | OpeningGroup[],
    geometries: Record<string, IGeometry[]> | null
): Generator<{}, Record<string, IBabylonLineWithMetadata>, Record<string, IBabylonLineWithMetadata>> {
    return yield drawableGroups
        .flatMap((joistGroup) => {
            // Extract each opening and flatten
            return joistGroup.openings.flatMap((opening) => {
                // Extract each opening location and flatten
                return opening.opening_locations.map((location) => {
                    return { ...location, type: opening.type, groupId: joistGroup.id }
                })
            })
        })
        .reduce((prev, cur) => {
            // Remove all joist area openings keeping only joist lines opening
            if (isOpeningLocationAJoistLine(cur)) {
                // Build the joist lines vector data and metadata
                const joistLineData = {
                    coordinates: cur.coordinates,
                    locationId: cur.id,
                    threeDID: cur.three_d_identifier,
                    openingId: cur.opening_id,
                    groupId: cur.groupId,
                } as JoistLinesRawData & { threeDID: string | null }

                const geometryArray = geometries && joistLineData.threeDID && geometries[joistLineData.threeDID]
                const geometry = geometryArray && geometryArray[0]
                const zIndices = geometry ? geometry.vertices.filter((x, i) => i % 3 === 2) : [0]
                const zMinValue = Math.min(...zIndices)
                const path: IVector[] = joistLineData.coordinates.map((coord) => {
                    return {
                        x: coord[0],
                        y: coord[1],
                        z: zMinValue,
                    }
                })
                const lengthLabel: string = Math.sqrt(
                    Math.pow(path[1].x - path[0].x, 2) +
                        Math.pow(path[1].y - path[0].y, 2) +
                        Math.pow(path[1].z - path[0].z, 2)
                )
                    .toFixed(0)
                    .toString()
                const joistLineDataWMetadata = {
                    path,
                    metaData: {
                        additionalData: {
                            ...cur.additional_data,
                            is_double_joist: cur.additional_data?.is_double_joist ?? false,
                        },
                        isReflectedInTwoD: false,
                        ids: [
                            {
                                locationId: joistLineData.locationId,
                                openingId: joistLineData.openingId,
                                groupId: joistLineData.groupId,
                                type: JOIST_LINE_DRAWABLE_NAME,
                            },
                        ],
                        storeyName: geometry ? geometry.metaData.storeyName : '',
                        isInterior: geometry ? geometry.metaData.isInterior : false,
                        label: lengthLabel,
                    },
                }

                prev[
                    `${joistLineDataWMetadata.metaData.ids[0].groupId}-${joistLineDataWMetadata.metaData.ids[0].openingId}-${joistLineDataWMetadata.metaData.ids[0].locationId}-${JOIST_LINES_SUFFIX}`
                ] = joistLineDataWMetadata as unknown as IBabylonLineWithMetadata
            }

            return prev
        }, {} as Record<string, IBabylonLineWithMetadata>)
}

export function* prepareJoistLinesData(
    drawableGroups: GeometryGroup[]
): Generator<
    | SelectEffect
    | CallEffect<Generator<{}, Record<string, IBabylonLineWithMetadata>, Record<string, IBabylonLineWithMetadata>>>
    | PutEffect<{ payload: Record<string, IBabylonLineWithMetadata> | null; type: string }>,
    void,
    Pick<State3D, 'geometries'> & Record<string, IBabylonLineWithMetadata>
> {
    const { geometries }: Pick<State3D, 'geometries'> = yield select(geometriesSelector)
    const joistData: Record<string, IBabylonLineWithMetadata> = yield call(
        calculateJoistDataFromDrawableGroups,
        drawableGroups,
        geometries
    )

    yield put(updateJoistLines(joistData))
}

export function* toggleDoubleJoistByMeshId(meshId: Mesh['id']) {
    const joistLinesData: Record<string, IBabylonLineWithMetadata> | null = yield select(joistLinesDataSelector)

    if (isNull(joistLinesData)) return joistLinesData

    const newJoistLinesData = {}

    Object.keys(joistLinesData).forEach((joistLineId) => {
        if (joistLineId === meshId) {
            return (newJoistLinesData[joistLineId] = {
                ...joistLinesData[joistLineId],
                metaData: {
                    ...joistLinesData[joistLineId].metaData,
                    additionalData: {
                        ...joistLinesData[joistLineId].metaData.additionalData,
                        is_double_joist: !joistLinesData[joistLineId].metaData.additionalData!.is_double_joist,
                    },
                },
            })
        }

        return (newJoistLinesData[joistLineId] = joistLinesData[joistLineId])
    })

    return newJoistLinesData
}
