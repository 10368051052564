import { createAction } from '@reduxjs/toolkit'
import * as JSOG from 'jsog'
import { all, call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects'

import { prepare3DTo2DMapping } from './prepare3DTo2DMapping'
import { prepareCorners } from './prepareCorners'
import { prepareGeometries } from './prepareGeometries'
import { prepareJoistLinesData } from './prepareJoistLinesData'
import { prepareJunctions } from './prepareJunctions'
import { prepareOpeningEdges } from './prepareOpeningEdges'
import { prepareOpeningsToMeshIdMappings } from './prepareOpeningsToMeshIdMappings'
import { preparePolygons } from './preparePolygons'
import { prepareRoofEdges } from './prepareRoofEdges'
import {
    getConfiguratorQuestionsAndAnswersJSON,
    getExteriorCornersJSON,
    getFloorJunctionsJSON,
    getGeometriesJSON,
    getOpeningSideJSON,
    getOverFramedRoofFacesJSON,
    getRoofFacesJSON,
    getRoofJunctionsJSON,
    getRoofSingleEdgesJSON,
    getStructureObjectJSON,
} from '../../../api/3D/file-storage'
import ModelUtils from '../../../components/IMUP3DComponent/babylon/ModelUtils'
import {
    currentModelIdSelector,
    fetchModelStructureFailure,
    projectNumberSelector,
    updateConfigurationQuestionAndAnswersData,
    updateOpeningsToMeshIdMap,
    updateOverFramedRoofFaces,
    updateRoofFaces,
    updateStructure,
} from '../../../slices/3D'
import { availableMode, update3DTo2DMappings } from '../../../slices/common'
import { GeometryGroup, selectDrawableGroupsGeometries } from '../../../slices/geometry'
import { requestPending, sceneRendering } from '../../../slices/loading'
import {
    ConfigurationQuestionAndAnswersResponse,
    IGeometry,
    IModel,
    JoistLinesRawData,
    ModelType,
    OpeningsResponse,
    ROOF_STOREY_NAME,
    ThreeDElementsResponse,
    ThreeDToTwoDRecord,
    VIEW_MODE,
} from '../../../types'
import SagaActionEnabledError from '../../utils/sagaBaseError'

export type GeometryInputMap = Record<string, IGeometry>
export type GeometryResultMap = Record<string, IGeometry[]>

export type GeometryAndStructureResponse = [
    IModel,
    GeometryInputMap,
    ThreeDElementsResponse | null,
    ThreeDElementsResponse | null,
    ThreeDElementsResponse | null,
    ThreeDElementsResponse | null,
    ThreeDElementsResponse | null,
    ThreeDElementsResponse | null,
    OpeningsResponse | null,
    ConfigurationQuestionAndAnswersResponse | null
]

export const prepare3DModelAction = createAction<boolean>('prepare3DModel')

// "Procedural" saga for handling the fetching and munging of data for 3d model viewing.
// Should "put" actions on the stack to populate resolved data in store
// Catch block can be used to "put" actions on the stack that populate error state in redux store
export function* prepare3DModel(
    shouldSetRendering = true
): Generator<
    StrictEffect,
    void,
    string &
        IModel &
        GeometryInputMap &
        OpeningsResponse &
        ThreeDElementsResponse &
        GeometryAndStructureResponse &
        ThreeDToTwoDRecord &
        Record<string, string> &
        GeometryGroup[] &
        JoistLinesRawData[]
> {
    try {
        yield put(requestPending())
        const portalProjectNumber = yield select(projectNumberSelector)
        const currentModelId = yield select(currentModelIdSelector)

        const [
            structure,
            geometries,
            roofFaces,
            overframedRoofFaces,
            exteriorCorners,
            roofSingleEdges,
            roofJunctions,
            floorJunctions,
            openings,
            configuratorQuestionsAndAnswers,
        ]: [
            IModel,
            GeometryInputMap,
            ThreeDElementsResponse | null,
            ThreeDElementsResponse | null,
            ThreeDElementsResponse | null,
            ThreeDElementsResponse | null,
            ThreeDElementsResponse | null,
            ThreeDElementsResponse | null,
            OpeningsResponse | null,
            ConfigurationQuestionAndAnswersResponse | null
        ] = yield all([
            call(getStructureObjectJSON, portalProjectNumber, currentModelId),
            call(getGeometriesJSON, portalProjectNumber, currentModelId),
            call(getRoofFacesJSON, portalProjectNumber, currentModelId),
            call(getOverFramedRoofFacesJSON, portalProjectNumber, currentModelId),
            call(getExteriorCornersJSON, portalProjectNumber, currentModelId),
            call(getRoofSingleEdgesJSON, portalProjectNumber, currentModelId),
            call(getRoofJunctionsJSON, portalProjectNumber, currentModelId),
            call(getFloorJunctionsJSON, portalProjectNumber, currentModelId),
            call(getOpeningSideJSON, portalProjectNumber, currentModelId),
            call(getConfiguratorQuestionsAndAnswersJSON, portalProjectNumber, currentModelId),
        ])

        if (structure && structure.children && geometries) {
            const drawableGroups: GeometryGroup[] = yield select(selectDrawableGroupsGeometries)
            const openingsToMeshIdMappings: Record<string, string> | null = yield call(
                prepareOpeningsToMeshIdMappings,
                JSOG.parse(JSON.stringify(openings))
            )
            const threeDToTwoDMappings: ThreeDToTwoDRecord | null = yield call(
                prepare3DTo2DMapping,
                openingsToMeshIdMappings,
                drawableGroups
            )

            const revitIdToConfigurationIDMap = yield call(ModelUtils.revitIdToConfigurationIdMap, structure)

            if (!threeDToTwoDMappings) {
                throw new Error('Error getting 2D mappings')
            }

            if (roofFaces) {
                yield call(
                    preparePolygons,
                    roofFaces,
                    threeDToTwoDMappings,
                    {
                        modelType: ModelType.ROOF,
                        storeyName: ROOF_STOREY_NAME,
                        isInterior: false,
                    },
                    revitIdToConfigurationIDMap,
                    updateRoofFaces
                )
            }

            if (overframedRoofFaces) {
                yield call(
                    preparePolygons,
                    overframedRoofFaces,
                    threeDToTwoDMappings,
                    {
                        modelType: ModelType.ROOF,
                        storeyName: ROOF_STOREY_NAME,
                        isInterior: false,
                    },
                    revitIdToConfigurationIDMap,
                    updateOverFramedRoofFaces
                )
            }

            if (roofSingleEdges) {
                yield call(
                    prepareRoofEdges,
                    roofSingleEdges,
                    threeDToTwoDMappings,
                    {
                        modelType: ModelType.ROOF,
                        storeyName: ROOF_STOREY_NAME,
                        isInterior: false,
                    },
                    revitIdToConfigurationIDMap
                )
            }

            if (exteriorCorners) {
                yield call(
                    prepareCorners,
                    exteriorCorners,
                    threeDToTwoDMappings,
                    {
                        modelType: ModelType.CORNER,
                        storeyName: '',
                        isInterior: false,
                    },
                    revitIdToConfigurationIDMap
                )
            }

            if (roofJunctions || floorJunctions) {
                yield call(
                    prepareJunctions,
                    { roofJunctions, floorJunctions },
                    threeDToTwoDMappings,
                    revitIdToConfigurationIDMap
                )
            }

            if (openings) {
                yield call(
                    prepareOpeningEdges,
                    openings,
                    threeDToTwoDMappings,
                    {
                        modelType: ModelType.WINDOW,
                        storeyName: '',
                        isInterior: false,
                    },
                    revitIdToConfigurationIDMap
                )
            }

            yield put(updateStructure(structure))

            // TODO: since in scope of BLUEPRINTS-14609 we optimize the rendering and the configurator is empty,
            //  we check if the data already come prepared in other case prevent error happening
            if (
                configuratorQuestionsAndAnswers?.hasOwnProperty('answers') &&
                configuratorQuestionsAndAnswers?.hasOwnProperty('phaseAndDesignOptions') &&
                configuratorQuestionsAndAnswers?.hasOwnProperty('questions')
            ) {
                yield put(updateConfigurationQuestionAndAnswersData(configuratorQuestionsAndAnswers))
            }

            yield put(update3DTo2DMappings(threeDToTwoDMappings))
            yield put(updateOpeningsToMeshIdMap(openingsToMeshIdMappings))
            yield call(prepareGeometries, structure, geometries, threeDToTwoDMappings)
            yield call(prepareJoistLinesData, drawableGroups)
            if (shouldSetRendering) yield all([put(availableMode(VIEW_MODE.Markup3D)), put(sceneRendering())])
        } else {
            throw new SagaActionEnabledError(fetchModelStructureFailure, 'Structure is invalid.')
        }
    } catch (error) {
        console.error(error)
        if ((error as any).actionToCall) {
            yield put((error as any).actionToCall((error as any).message))
        }
    }
}

export function* watchForPrepare3DModelAction() {
    yield takeEvery(prepare3DModelAction, prepare3DModel)
}
