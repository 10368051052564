import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import update from 'immutability-helper'
import isUndefined from 'lodash/isUndefined'

import { categoryNamePlaceholder, TaskOptions } from '../../components/admin/constants'
import {
    AdminMovingElement,
    LastUpdatedTime,
    MTPReference,
    PreparedAdminOption,
    PreparedCategoryAdminOption,
    PreparedSubCategoryAdminOption,
    PreparedSubCategoryChildrenOption,
    ResponseAdminOptions,
} from '../../models/adminOptions'
import { RootState } from '../../stores'

export type StateAdmin = {
    activeTab: TaskOptions

    statuses: PreparedAdminOption[]
    severities: PreparedAdminOption[]
    categories: PreparedCategoryAdminOption[]
    subCategories: PreparedSubCategoryAdminOption[]

    lastUpdatedTime: LastUpdatedTime
}

export const initialAdminState: StateAdmin = {
    activeTab: TaskOptions.STATUSES,
    statuses: [],
    severities: [],
    categories: [],
    subCategories: [],
    lastUpdatedTime: {
        statuses: '',
        severities: '',
        categories: '',
        subCategories: '',
    },
}

const sortByOrder = (a, b) => {
    return a.order - b.order
}

/**
 * Function check for id or fakeId
 * id - is used when option is added and saved, after that we have id from database, and we can move/delete by id
 * fakeId - is used when option is added and wasn't saved, we need it to be able to move/delete it without using option name.
 *
 * Option name can be the same.
 *
 * @param currentOption
 * @param optionId
 * @param optionFakeId
 */
const checkByIdOrFakeId = (
    currentOption: PreparedAdminOption,
    optionId: number | undefined,
    optionFakeId: string
): boolean => {
    if (!isUndefined(optionId)) {
        return currentOption.id === optionId
    }

    return currentOption.fakeId === optionFakeId
}

const handleSetOptions = (state: StateAdmin, { payload }: PayloadAction<{ options: ResponseAdminOptions }>) => {
    const {
        statuses = { items: [], lastChangeTime: '' },
        severities = { items: [], lastChangeTime: '' },
        categories = { items: [], lastChangeTime: '' },
        subCategories = { items: [], lastChangeTime: '' },
    } = payload.options

    state.statuses = statuses.items
    state.severities = severities.items
    state.categories = categories.items
    state.subCategories = categories.items.map((category) => ({
        ...category,
        children: subCategories.items.filter((subcategory) => subcategory.category_id === category.id),
    }))

    state.lastUpdatedTime = {
        statuses: statuses.lastChangeTime,
        severities: severities.lastChangeTime,
        categories: categories.lastChangeTime,
        subCategories: subCategories.lastChangeTime,
    }
}

const handleSetNewOption = (
    state: StateAdmin,
    {
        payload,
    }: PayloadAction<{ optionValue: string; fakeId: string; isCopied?: boolean; isNew?: boolean; categoryId?: number }>
) => {
    const { optionValue, fakeId, categoryId, isNew, isCopied } = payload

    if (state.activeTab === TaskOptions.SUBCATEGORIES) {
        if (categoryId) {
            state.subCategories = state.subCategories.map((subCategory) => {
                if (subCategory.id === categoryId) {
                    return {
                        ...subCategory,
                        children: [
                            ...subCategory.children,
                            {
                                name: optionValue,
                                category_id: categoryId,
                                isCopied: isCopied,
                                isNew: isNew,
                                order: subCategory.children.length + 1,
                                mtp_references: [],
                                fakeId,
                            },
                        ],
                    }
                }

                return subCategory
            })

            return
        }

        const newCategoryPlaceholder = state.subCategories.find((category) => category.name === categoryNamePlaceholder)

        if (newCategoryPlaceholder) {
            // add subcategory into "New Categories"
            state.subCategories = state.subCategories.map((subCategory) => {
                if (subCategory.name === newCategoryPlaceholder.name) {
                    return {
                        ...subCategory,
                        children: [
                            { name: optionValue, category_id: -1, isNew: true, order: 1, mtp_references: [], fakeId },
                            ...subCategory.children,
                        ].map((option, index) => ({ ...option, order: index + 1 })),
                    }
                }

                return subCategory
            })
        } else {
            // create "New Categories" temporary category and add first subcategory
            state.subCategories = [
                {
                    name: categoryNamePlaceholder,
                    order: 1,
                    children: [
                        { name: optionValue, isNew: true, category_id: -1, order: 1, mtp_references: [], fakeId },
                    ],
                    mtp_references: [],
                    id: -1,
                },
                ...state.subCategories,
            ].map((option, index) => ({ ...option, order: index + 1 }))
        }

        return
    }

    if (state.activeTab === TaskOptions.CATEGORIES) {
        state.categories = [{ name: optionValue, isNew: true, order: 1, mtp_references: [] }, ...state.categories].map(
            (option, index) => ({ ...option, order: index + 1 })
        )

        state.subCategories = [
            { name: optionValue, isNew: true, children: [], order: 1, mtp_references: [], fakeId },
            ...state.subCategories,
        ].map((option, index) => ({ ...option, order: index + 1 }))
    }

    if (state.activeTab === TaskOptions.STATUSES || state.activeTab === TaskOptions.SEVERITIES) {
        // rest of options
        state[state.activeTab] = [{ name: optionValue, isNew: true, order: 1, fakeId }, ...state[state.activeTab]].map(
            (option, index) => ({ ...option, order: index + 1 })
        ) as PreparedAdminOption[]
    }
}

const handleResetNewOptions = (state: StateAdmin, { payload }: PayloadAction<{ optionName: string }>) => {
    const { optionName } = payload

    // when a category is removed, remove it from subcategory and remove mpt references
    if (state.activeTab === TaskOptions.CATEGORIES) {
        // remove mtp_references from categories when mtp_reference is new and we reset
        state.categories = state.categories.map((category) => {
            return {
                ...category,
                mtp_references: category.mtp_references.filter((mtpRef) => !mtpRef.isNew),
            }
        })

        // remove mtp_references from subcategories when mtp_reference is new and we reset
        state.subCategories = state.subCategories
            .map((subCategory) => {
                return {
                    ...subCategory,
                    mtp_references: subCategory.mtp_references.filter((mtpRef) => !mtpRef.isNew),
                }
            })
            .filter((subCategory) => !subCategory.isNew)
    }

    if (state.activeTab === TaskOptions.SUBCATEGORIES) {
        state.subCategories = state.subCategories.map((subcategory) => {
            return {
                ...subcategory,
                children: subcategory.children
                    .map((option) => {
                        return {
                            ...option,
                            isSoftDeleted: false,
                            mtp_references: option.mtp_references.filter((mtpRef) => !mtpRef.isNew),
                        }
                    })
                    .filter((option) => !option.isNew),
            }
        })

        return
    }

    state[optionName] = (state[optionName] as PreparedAdminOption[])
        .map((option) => ({ ...option, isSoftDeleted: false })) // display deleted items
        .filter((option) => !option.isNew) // remove new options
        .sort(sortByOrder)
}

const handleSoftDeleteOption = (
    state: StateAdmin,
    { payload }: PayloadAction<{ optionId: number; optionFakeId: string }>
) => {
    const { optionId, optionFakeId } = payload

    if (state.activeTab === TaskOptions.SUBCATEGORIES) {
        // remove a new option or mark isSoftDeleted if we can restore it
        state.subCategories = state.subCategories
            .map((subcategory) => {
                return {
                    ...subcategory,
                    children: subcategory.children
                        // mark option isSoftDeleted to hide it on FE
                        .map((option) => {
                            return checkByIdOrFakeId(option, optionId, optionFakeId)
                                ? { ...option, isSoftDeleted: true }
                                : option
                        })
                        // filter option if options are new and were deleted, we don't need keep it
                        .filter((option) => {
                            const isSameIdOrFakeId = checkByIdOrFakeId(option, optionId, optionFakeId)

                            return !(isSameIdOrFakeId && option.isNew && option.isSoftDeleted)
                        }),
                }
            })
            .filter(
                (subcategory) => !(subcategory.name === categoryNamePlaceholder && subcategory.children.length === 0)
            )

        return
    }

    // when we delete the category, remove the category in subcategories
    if (state.activeTab === TaskOptions.CATEGORIES) {
        state.subCategories = state.subCategories.map((subcategory) => {
            const isSameIdOrFakeId = checkByIdOrFakeId(subcategory, optionId, optionFakeId)

            if (isSameIdOrFakeId) {
                return {
                    ...subcategory,
                    isSoftDeleted: true,
                }
            }

            return subcategory
        })
    }

    state[state.activeTab] = state[state.activeTab]
        .map((option) => {
            const isSameIdOrFakeId = checkByIdOrFakeId(option, optionId, optionFakeId)

            return isSameIdOrFakeId ? { ...option, isSoftDeleted: true } : option
        })
        .filter((option) => {
            const isSameIdOrFakeId = checkByIdOrFakeId(option, optionId, optionFakeId)

            return !(isSameIdOrFakeId && option.isNew && option.isSoftDeleted)
        })
}

const handleSetActiveTab = (state: StateAdmin, { payload }: PayloadAction<{ activeTab: TaskOptions }>) => {
    state.activeTab = payload.activeTab
}

const handleNewOrder = (
    state: StateAdmin,
    {
        payload,
    }: PayloadAction<{
        dragIndex: number
        hoverIndex: number
        movingElement: AdminMovingElement
        moveToGroup: string
    }>
) => {
    const { dragIndex, hoverIndex, movingElement, moveToGroup } = payload

    const isMovingInsideSameGroup = movingElement.groupName === moveToGroup

    if (state.activeTab === TaskOptions.CATEGORIES) {
        state.categories = update(state.categories, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, state.categories[dragIndex]],
            ],
        })
    }

    if (state.activeTab === TaskOptions.SUBCATEGORIES) {
        state.subCategories = state.subCategories.map((subcategory) => {
            // move inside the same group, change the order of elements
            if (isMovingInsideSameGroup && subcategory.name === movingElement.groupName) {
                return {
                    ...subcategory,
                    children: update(subcategory.children, {
                        $splice: [
                            [dragIndex, 1],
                            [hoverIndex, 0, subcategory.children[dragIndex]],
                        ],
                    }),
                }
            }

            return subcategory
        })
    }

    if (state.activeTab === TaskOptions.STATUSES || state.activeTab === TaskOptions.SEVERITIES) {
        state[state.activeTab] = update(state[state.activeTab], {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, state[state.activeTab][dragIndex]],
            ],
        })
    }
}

const handleMoveBetween = (
    state: StateAdmin,
    {
        payload,
    }: PayloadAction<{
        movingElement: AdminMovingElement
        moveTo: string
    }>
) => {
    const {
        movingElement: { name, isNew, category_id, groupName, fakeId },
        moveTo,
    } = payload

    const elementToAdd = {
        fakeId,
        name,
        isNew,
        category_id,
        mtp_references: [],
    }

    state.subCategories = state.subCategories
        .map((subcategory, index) => {
            // on the move, remove subcategory from an initial group
            if (subcategory.name === groupName) {
                return {
                    ...subcategory,
                    order: index + 1,
                    children: subcategory.children.filter((child) => child.fakeId !== fakeId),
                }
            }

            // on the move, move subcategory to a new group
            if (subcategory.name === moveTo) {
                return {
                    ...subcategory,
                    order: index + 1,
                    children: [...subcategory.children, elementToAdd].map((option, index) => ({
                        ...option,
                        category_id: subcategory.id,
                        order: index + 1,
                    })),
                }
            }

            return { ...subcategory, order: index + 1 }
        })
        .filter((subcategory) => !(subcategory.name === categoryNamePlaceholder && !subcategory.children.length))
}

const handleAddMptReference = (
    state: StateAdmin,
    {
        payload,
    }: PayloadAction<{
        item: PreparedCategoryAdminOption | PreparedSubCategoryChildrenOption
        mtpReferenceName: string
    }>
) => {
    if (state.activeTab === TaskOptions.SUBCATEGORIES) {
        state.subCategories = state.subCategories.map((option) => {
            if (option.id === (payload.item as PreparedSubCategoryChildrenOption).category_id) {
                return {
                    ...option,
                    children: option.children.map((child) => {
                        if (child.id === payload.item.id) {
                            return {
                                ...child,
                                mtp_references: child.mtp_references
                                    ? [{ name: payload.mtpReferenceName, isNew: true }, ...child.mtp_references]
                                    : [{ name: payload.mtpReferenceName, isNew: true }],
                            }
                        }

                        return child
                    }),
                }
            }

            return option
        })
    }

    if (state.activeTab === TaskOptions.CATEGORIES) {
        state.categories = state.categories.map((option) => {
            if (option.id === payload.item.id) {
                return {
                    ...option,
                    mtp_references: option?.mtp_references
                        ? [{ name: payload.mtpReferenceName, isNew: true }, ...option.mtp_references]
                        : [{ name: payload.mtpReferenceName, isNew: true }],
                }
            }

            return option
        })
    }
}

const getUpdateMTPReferenceWithSoftDeleted = (
    mtp_references: MTPReference[],
    mtpReference: MTPReference
): MTPReference[] => {
    return mtp_references
        .map((reference) => {
            if ((mtpReference?.id && mtpReference.id === reference.id) || mtpReference.name === reference.name) {
                return {
                    ...reference,
                    isSoftDeleted: true,
                }
            }

            return reference
        })
        .filter((reference) => {
            return !(reference.name === mtpReference.name && reference.isNew && reference.isSoftDeleted)
        })
}

const handleSoftDeleteMtpReference = (
    state: StateAdmin,
    {
        payload,
    }: PayloadAction<{
        item: PreparedCategoryAdminOption | PreparedSubCategoryChildrenOption
        mtpReference: MTPReference
    }>
) => {
    if (state.activeTab === TaskOptions.CATEGORIES) {
        state.categories = state.categories.map((option) => {
            if (option.id === payload.item.id) {
                return {
                    ...option,
                    mtp_references: getUpdateMTPReferenceWithSoftDeleted(option.mtp_references, payload.mtpReference),
                }
            }

            return option
        })
    }

    if (state.activeTab === TaskOptions.SUBCATEGORIES) {
        state.subCategories = state.subCategories.map((option) => {
            const categotyId = (payload.item as PreparedSubCategoryChildrenOption).category_id

            if (option.id === categotyId) {
                return {
                    ...option,
                    children: option.children.map((childrenOption) => {
                        if (childrenOption.id === payload.item.id) {
                            return {
                                ...childrenOption,
                                mtp_references: getUpdateMTPReferenceWithSoftDeleted(
                                    childrenOption.mtp_references,
                                    payload.mtpReference
                                ),
                            }
                        }

                        return childrenOption
                    }),
                }
            }

            return option
        })
    }
}

const reducers = {
    setActiveTab: handleSetActiveTab,
    setOptions: handleSetOptions,
    setNewOption: handleSetNewOption,
    softDeleteOption: handleSoftDeleteOption,
    resetNewOptions: handleResetNewOptions,
    setNewOrder: handleNewOrder,
    moveBetween: handleMoveBetween,
    addMtpReference: handleAddMptReference,
    softDeleteMtpReference: handleSoftDeleteMtpReference,
}

const adminSlice = createSlice({
    name: 'admin',
    initialState: initialAdminState,
    reducers,
})

export const {
    setActiveTab,
    setOptions,
    setNewOption,
    softDeleteOption,
    resetNewOptions,
    setNewOrder,
    moveBetween,
    addMtpReference,
    softDeleteMtpReference,
} = adminSlice.actions

export const selectActiveTab = createSelector(
    (state: RootState) => state.IMUP.admin.activeTab,
    (activeTab) => activeTab
)

export const selectLastUpdated = createSelector(
    (state: RootState) => state.IMUP.admin.lastUpdatedTime,
    (lastUpdatedTime) => lastUpdatedTime
)

export const selectOptions = createSelector(
    (state: RootState) => {
        return {
            statuses: state.IMUP.admin.statuses,
            severities: state.IMUP.admin.severities,
            categories: state.IMUP.admin.categories,
            subCategories: state.IMUP.admin.subCategories,
        }
    },
    (options) => options
)

export default adminSlice
