import { put } from 'redux-saga/effects'

import { updateOpeningEdges } from '../../../slices/3D'
import {
    IBabylonLineWithMetadata,
    IOpeningEdgeData,
    ModelType,
    OpeningsResponse,
    RevitIdToConfigIdMap,
    ThreeDToTwoDRecord,
} from '../../../types'

export const reduceOpeningsResponseToData =
    (
        openingResponse: OpeningsResponse,
        threeDToTwoDMappings: ThreeDToTwoDRecord,
        metadata: {
            modelType: ModelType
            storeyName: string
            isInterior: boolean
        },
        geometriesRevitIdToConfigIdMap: RevitIdToConfigIdMap
    ) =>
    (openingDictionary: IBabylonLineWithMetadata, id: string) => {
        const openingData = openingResponse[id]
        const edgeNames = Object.keys(openingData.edgesMap)

        edgeNames.forEach((edgeName) => {
            const parsedData = openingData.edgesMap[edgeName] as IOpeningEdgeData[]

            parsedData.forEach((edge, i) => {
                const indexAddition = '+' + i
                const id = `${openingData.meshId}-${edgeName}${parsedData.length > 1 ? indexAddition : ''}`

                openingDictionary[id] = {
                    path: [
                        { x: edge.p1.x, y: edge.p1.y, z: -edge.p1.z },
                        { x: edge.p2.x, y: edge.p2.y, z: -edge.p2.z },
                    ],
                    metaData: {
                        isReflectedInTwoD: !!threeDToTwoDMappings[id.split('+')[0]],
                        ids: threeDToTwoDMappings[id.split('+')[0]] ? threeDToTwoDMappings[id.split('+')[0]] : [],
                        isInterior: metadata.isInterior,
                        storeyName: metadata.storeyName,
                        modelType: metadata.modelType,
                        configuration_id: geometriesRevitIdToConfigIdMap[openingData.customRevitId],
                    },
                }
            })
        })

        return openingDictionary
    }

export function* prepareOpeningEdges(
    openingResponse: OpeningsResponse | null,
    threeDToTwoDMappings: ThreeDToTwoDRecord,
    metadata: {
        modelType: ModelType
        storeyName: string
        isInterior: boolean
    },
    geometriesRevitIdToConfigIdMap: RevitIdToConfigIdMap
) {
    if (!openingResponse) return

    const openingEdgesDictionary: Record<string, IBabylonLineWithMetadata> = yield Object.keys(openingResponse).reduce(
        reduceOpeningsResponseToData(openingResponse, threeDToTwoDMappings, metadata, geometriesRevitIdToConfigIdMap),
        {} as IBabylonLineWithMetadata
    )

    yield put(updateOpeningEdges(openingEdgesDictionary))
}
