import { State3D } from '../../../slices/3D'
import { IOpeningRaw, OpeningsResponse } from '../../../types'

export function* prepareOpeningsToMeshIdMappings(
    openings: OpeningsResponse | null
): Generator<State3D['openingsToMeshIdMap'], State3D['openingsToMeshIdMap'], State3D['openingsToMeshIdMap']> {
    return !openings
        ? null
        : yield Object.keys(openings).reduce((openingsToMeshIdMap, openingId) => {
              const openingData: IOpeningRaw = openings[openingId]

              Object.keys(openingData.edgesMap).forEach((key) => {
                  openingsToMeshIdMap[`${openingData.customRevitId}-${key}`] = `${openingData.meshId}-${key}`
              })

              openingsToMeshIdMap[openingData.customRevitId] = openingData.meshId

              return openingsToMeshIdMap
          }, {})
}
