// NOTE: not all packages
export enum ProjectPackages {
    Comprehensive = 'Comprehensive',
    ComprehensiveAndHomeConfigure = 'Comprehensive + Home Configure',
    Exteriors = 'Exteriors',
    Windows = 'Windows',
    HomeConfigureContractedBuyerExteriorOnly = 'Home Configure: Contracted Buyer Exterior Only',
    HomeConfigureContractedBuyerExteriorPlusInteriorSpotlight = 'Home Configure: Contracted Buyer Exterior + Interior Spotlight',
    HomeConfigureExteriorsPlusSpotlightSpaces = 'Home Configure: Exteriors + Spotlight Spaces',
    HomeConfigureExteriors = 'Home Configure: Exteriors',
    HomeConfigureProspectiveBuyerExteriorOnly = 'Home Configure: Prospective Buyer Exterior Only',
    HomeConfigureProspectiveBuyerExteriorPlusInteriorSpotlight = 'Home Configure: Prospective Buyer Exterior + Interior Spotlight',
}
