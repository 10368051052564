import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

const roomsFlag = import.meta.env.VITE_ROOMS_FLAG === 'true'

export const uiSchema: UiSchema = {
    'ui:order': [
        'sub_floor',
        'sub_floor_adhesive',
        'is_wet_area',
        'location',
        'baseboard',
        'shoe_mould',
        'chair_rail',
        'crown_mould',
    ],
    location: {
        'ui:widget': 'hidden',
    },
    sub_floor: {
        'ui:title': 'Subfloor',
        'ui:placeholder': 'Select Subfloor',
    },
    sub_floor_adhesive: {
        'ui:title': 'Subfloor Adhesive',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    is_wet_area: {
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    baseboard: {
        'ui:widget': roomsFlag ? 'switch' : 'hidden',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    shoe_mould: {
        'ui:widget': roomsFlag ? 'radio' : 'hidden',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    chair_rail: {
        'ui:widget': roomsFlag ? 'switch' : 'hidden',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    crown_mould: {
        'ui:widget': roomsFlag ? 'switch' : 'hidden',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    return { ...settings, ...formData }
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
