import { UiSchema } from '@rjsf/core'

import { FormProperties } from '../imup/types'

export const uiSchema: UiSchema = {
    label: {
        'ui:title': 'Label',
    },
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    newSettings.label = !formData.label && settings.label ? null : formData.label

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
