import { createAction } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'

import { createFlag } from '../../../api/projects-api'
import {
    FlagAPIPayload,
    FlagAPIResponse,
    FlagEnum,
    NewOpeningLink,
    NormalizedFlag,
    OpeningLink,
} from '../../../models/flags'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Flag } from '../../lib/toolBoxes/2D'
import { selectActiveFloorId } from '../../slices/documents'
import { addNewFlag, selectMaterialFlags, setActiveFlag } from '../../slices/materialFlags'

export const copyFlagAction = createAction<{
    flagId: number
    newUserEmail: string
    newUserName: string
}>('copyFlag')

const shiftCoordinates = 50

export function* handleCopyFlag({ payload }: ReturnType<typeof copyFlagAction>) {
    try {
        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (!manager) return
        const flagTool: Flag = yield call(manager.getTool, Flag.NAME)

        const activeFloorId: number = yield select(selectActiveFloorId)
        const flags: NormalizedFlag[] = yield select(selectMaterialFlags)

        const foundFlag = flags.find((f) => f.id === payload.flagId)

        if (!foundFlag) return

        const firstOpenLink: NewOpeningLink = foundFlag.opening_links[0]

        // create a flag open link with new coordinates + small shift and with unlinked opening_id
        const openLinkToCreate: NewOpeningLink = {
            opening_id: null,
            coordinates: [
                firstOpenLink.coordinates[0] + shiftCoordinates,
                firstOpenLink.coordinates[1] - shiftCoordinates,
            ],
            document_chunk_id: firstOpenLink.document_chunk_id,
        }

        const flagToCreateData: FlagAPIPayload = {
            created_by_username: payload.newUserName,
            created_by_user_email: payload.newUserEmail,
            category_id: foundFlag.category?.id ? Number(foundFlag.category.id) : undefined,
            sub_category_id:
                foundFlag.status && foundFlag.sub_category?.id ? Number(foundFlag.sub_category.id) : undefined,
            severity_id: foundFlag.severity?.id ? Number(foundFlag.severity.id) : undefined,
            status_id: foundFlag.status?.id ? Number(foundFlag.status.id) : undefined,
            type_id: Number(foundFlag.type?.id),
            description: foundFlag.description,
            opening_links: [openLinkToCreate],
        }

        const response: FlagAPIResponse = yield call(
            createFlag,
            foundFlag.project_id,
            flagToCreateData as FlagAPIPayload
        )

        const updatedOpeningLinks: OpeningLink[] = response?.opening_links.filter(
            (link: OpeningLink) => link.document_chunk_id === activeFloorId
        )

        if (!!updatedOpeningLinks?.length) {
            const type = response.type_id === 1 ? FlagEnum.TASK : FlagEnum.NOTE

            // add saved flags to blueprint
            yield all(
                updatedOpeningLinks.map((link) =>
                    call(
                        flagTool.drawFlagByCoords,
                        link.coordinates,
                        response.id,
                        link.opening_id,
                        response.order,
                        type
                    )
                )
            )

            // save flags in store
            yield put(addNewFlag({ flag: response }))

            // save flags in store
            yield put(setActiveFlag({ flag_id: response.id }))
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForCopyFlag(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(copyFlagAction.type, handleCopyFlag)
}
