import { createAction } from '@reduxjs/toolkit'
import { call, select, takeEvery } from 'redux-saga/effects'

import { AI_MATERIAL_REFLECTED_GRADIENT_COLORS, DRAWABLE_TYPES } from '../../../shared/constants/drawable-types'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Workspace } from '../../lib/toolBoxes/2D'
import { getSelectedMaterial, selectedAiSuggestionIds } from '../../slices/2D'

export const resetAIDrawables = createAction('resetAIDrawables')

export function* handleResetAIDrawables() {
    const selectedIds = yield select(selectedAiSuggestionIds)
    const type: DRAWABLE_TYPES = yield select(getSelectedMaterial)

    const manager: PaperManager = yield call(managers.get2DManager)

    if (!manager) return
    const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)
    const colorTool: Color = yield call(manager.getTool, Color.NAME)

    const aiItems: paper.Item[] = yield call(workspaceTool.getItemsWithCriteria, 'data', function (data: any) {
        return selectedIds.map((suggestionID) => suggestionID).includes(data?.aiSuggestion?.id)
    })

    const gradientColors = AI_MATERIAL_REFLECTED_GRADIENT_COLORS[type]

    for (const drawable of aiItems) {
        const gradientColor = yield call(
            colorTool.createReflectedGradient,
            gradientColors.outerColor,
            gradientColors.innerColor,
            drawable.data.aiSuggestion.coordinates
        )

        drawable.strokeColor = gradientColor
    }
}

export function* watchForAIDrawablesReset() {
    yield takeEvery(resetAIDrawables.type, handleResetAIDrawables)
}
