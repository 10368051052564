import store from '../../../../stores'
import { saveEditsToHighlight } from '../../../sagas/2D/editHighlights'
import { setActiveHighlightId, setAltSelectedItems, updateSelectedPaperId } from '../../../slices/2D'
import { IMUP2DCoordinatesToUpdate, ROUNDED_CORNER_SIZE_IN_PX } from '../../../types'
import managers from '../../managers'
import { PathTool } from '../../toolBoxes/2D'

function save2DHighlight(): (coordinatesToUpdate: IMUP2DCoordinatesToUpdate, projectId: number) => void {
    return function saveHighlight(this: paper.Path, coordinatesToUpdate) {
        store.dispatch(
            saveEditsToHighlight({
                coordsMeasurementsAndCutouts: coordinatesToUpdate,
                highlightDrawableId: this.data.drawable_id,
                highlightLocationId: this.data.opening_location_id,
            })
        )

        // OriginalCoordinates are needed for path rounding and derounding
        // so updated on each save
        this.data.originalCoordinates = coordinatesToUpdate.coordinates
    }
}

function select2DHighlight(): () => void {
    return function selectHighlight(this: paper.Path) {
        store.dispatch(setActiveHighlightId(this.id))

        const manager = managers.get2DManager()

        if (manager) {
            const pathTool: PathTool = manager.getTool(PathTool.NAME)

            pathTool.deroundPath(this)
            store.dispatch(updateSelectedPaperId(this.id))
        }
    }
}

function deselect2DHighlight(): () => void {
    return function deselectHighlight(this: paper.Path) {
        store.dispatch(setActiveHighlightId(null))

        // reset multiple selected items
        store.dispatch(setAltSelectedItems(null))

        const manager = managers.get2DManager()

        if (manager) {
            const pathTool: PathTool = manager.getTool(PathTool.NAME)

            pathTool.roundPath(this, ROUNDED_CORNER_SIZE_IN_PX)
            store.dispatch(updateSelectedPaperId(null))
        }
    }
}

export default function addSelectFunctionalityToHighlight(path: paper.PathItem): void {
    path.data.isHighlight = true
    path.data.onSave = save2DHighlight().bind(path)
    path.data.onSelect = select2DHighlight().bind(path)
    path.data.onDeselect = deselect2DHighlight().bind(path)
}
