import { call, put, select } from 'redux-saga/effects'

import { updateSingleDocumentMapping } from '../effects/updateSingleDocumentMapping'
import { deleteDocument } from '../../../api/projects-api'
import { Coordinate } from '../../../models/activeDrawable'
import { DocumentMapping } from '../../../models/documentMapping'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { MasterSetPlanRegion, Select } from '../../lib/toolBoxes/2D'
import {
    hardUpdateDocumentMappings,
    NormalizedDocumentChunk,
    selectDocumentMappings,
    selectNormalizedDocumentChunks,
    setDocumentChunks,
} from '../../slices/documents'
import { Geometry, selectGeometries } from '../../slices/geometry'
import { Coordinates2D } from '../../types'

export const PARENT_MAPPING_OPTIONS_KEY = 'options'
export const PARENT_MAPPING_BASE_HOMES_KEY = 'baseHomes'

export const returnParentMappingKey = (mapping: DocumentMapping) =>
    mapping.is_option ? PARENT_MAPPING_OPTIONS_KEY : PARENT_MAPPING_BASE_HOMES_KEY

export function* deleteMasterSetOptionOrBaseHome(items: paper.Item[]) {
    const paperManager: PaperManager | null = yield call(managers.get2DManager)

    if (!paperManager) return

    const documentMappings: DocumentMapping[] = yield select(selectDocumentMappings)
    const documentChunks: NormalizedDocumentChunk[] = yield select(selectNormalizedDocumentChunks)
    const documentMappingIds: number[] = items.map((item) => item.data.masterSetPlanId)
    const geometries: Geometry[] = yield select(selectGeometries)

    const [masterSetPlanRegionTool, selectTool]: [MasterSetPlanRegion, Select] = yield call(paperManager.getTools, [
        MasterSetPlanRegion.NAME,
        Select.NAME,
    ])

    if (documentMappingIds.length > 1) {
        yield call(console.warn, 'Cannot delete more than one mapping, ignoring the rest')
    }

    const documentMappingToDelete: DocumentMapping | undefined = documentMappings.find(
        (dm) => dm.id === documentMappingIds[0]
    )

    if (!documentMappingToDelete) return

    const geometriesInPage = geometries.find(
        (geo) => geo.document_chunk_id === documentMappingToDelete.document_chunk_id
    )

    if (geometriesInPage) return

    const parentDocumentMapping: DocumentMapping | undefined = documentMappings.find(
        (dm) => dm.id === documentMappingToDelete.additional_data.parentDocumentMapping
    )

    if (!parentDocumentMapping) return

    const updatedParentDocumentMapping: DocumentMapping = {
        ...parentDocumentMapping,
        additional_data: {
            ...parentDocumentMapping.additional_data,
            [returnParentMappingKey(documentMappingToDelete)]: parentDocumentMapping.additional_data[
                returnParentMappingKey(documentMappingToDelete)
            ]?.filter((idAndCoords) => {
                return idAndCoords.id !== documentMappingToDelete.id
            }),
        },
    }

    const potentialCoordinatesAndIdOfDocumentToBeDeleted: { id: number; coordinates: Coordinate[] } | undefined =
        parentDocumentMapping.additional_data[returnParentMappingKey(documentMappingToDelete)]?.find(
            ({ id }) => id === documentMappingIds[0]
        )

    if (!potentialCoordinatesAndIdOfDocumentToBeDeleted) return

    const newDocumentMappings: DocumentMapping[] = documentMappings.filter((dm) => dm.id !== documentMappingToDelete.id)

    yield call(masterSetPlanRegionTool.handleDeleteMasterSetPlanPath, items[0] as paper.Path)

    yield call(selectTool.exitSelectMode)

    yield put(hardUpdateDocumentMappings(newDocumentMappings))

    yield put(updateSingleDocumentMapping(updatedParentDocumentMapping))

    yield put(
        setDocumentChunks({
            documentChunks: documentChunks.filter((chunk) => chunk.id !== documentMappingToDelete.document_chunk_id),
        })
    )

    try {
        yield call(deleteDocument, documentMappingIds[0])
    } catch (e) {
        yield call(console.error, e)

        yield put(hardUpdateDocumentMappings(documentMappings))

        yield put(setDocumentChunks({ documentChunks }))

        yield call(
            masterSetPlanRegionTool.renderBaseHomeOptionPageRegion,
            potentialCoordinatesAndIdOfDocumentToBeDeleted.coordinates as Coordinates2D,
            documentMappingIds[0],
            documentMappingToDelete.is_option
        )
    }
}
