import { createAction } from '@reduxjs/toolkit'
import { call, fork, put, select, takeLatest } from 'redux-saga/effects'

import { getAndActivateFormByType } from '../effects/getFormByType'
import { handleCloseMaterialForm } from '../effects/handleCloseMaterialForm'
import { GeneralDrawableSettings } from '../../../models/activeDrawableSettings'
import { AzureFeatureFlag } from '../../../models/azureFeatureFlags'
import { AzureFeatureFlagIds } from '../../../shared/constants/azure-feature-flags'
import {
    AI_MATERIAL_REFLECTED_GRADIENT_COLORS,
    DRAWABLE_TYPES,
    SELECTED_AI_SUGGESTION_COLOR,
} from '../../../shared/constants/drawable-types'
import { FormTypes } from '../../../shared/constants/form'
import { isAzureFeatureFlagEnabled } from '../../../shared/services/azure-feature-flag-services/azure-feature-flag-services'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Workspace } from '../../lib/toolBoxes/2D'
import {
    createDrawableLocation,
    deleteSelectedAiSuggestionIds,
    getSelectedMaterial,
    selectAiSuggestions,
    selectedAiSuggestionIds,
    selectHiddenAIIds,
    setAiSuggestionSettings,
    setSelectedAiSuggestionIds,
    updateSelectedMaterialType,
} from '../../slices/2D'
import { selectAzureFeatureFlags } from '../../slices/flags'
import { selectSnappingEnabled } from '../../slices/tools'

export const updateSelectedAISuggestion = createAction<{
    aiSuggestionIDs: string[]
    aiDrawableID?: number
    isGroupVisible?: boolean
}>('updateSelectedAISuggestion')

export function* handleUpdateSelectedAISuggestion(aiSuggestionPayload: {
    type: string
    payload: { aiSuggestionIDs: string[]; aiDrawableID?: number; isGroupVisible?: boolean }
}) {
    try {
        const selectedIDs = aiSuggestionPayload.payload.aiSuggestionIDs
        const azureFeatureFlags: AzureFeatureFlag[] | null = yield select(selectAzureFeatureFlags)
        const selectedMaterialType: DRAWABLE_TYPES = yield select(getSelectedMaterial)
        const aiSuggestions = yield select(selectAiSuggestions)
        const hiddenAIIds: string[] = yield select(selectHiddenAIIds)

        const isAISuggestionsFilterFlagEnabled: boolean = yield call(
            isAzureFeatureFlagEnabled,
            azureFeatureFlags,
            AzureFeatureFlagIds.ai_suggestions_filter
        )

        if (isAISuggestionsFilterFlagEnabled) {
            // get strokeColor for drawables
            const manager: PaperManager = yield call(managers.get2DManager)

            if (!manager) return

            const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)
            const isSnappingEnabled = yield select(selectSnappingEnabled)

            const aiDrawables: paper.Path[] = yield call(
                workspaceTool.getItemsWithCriteria,
                'data',
                function (data: any) {
                    return selectedIDs.includes(data?.aiSuggestion?.id)
                }
            )

            for (const aiDrawable of aiDrawables) {
                const selectedAISuggestionID = aiDrawable.data.aiSuggestion.id
                const colorTool: Color = yield call(manager.getTool, Color.NAME)
                const selectedColor = yield call(colorTool.createColor, SELECTED_AI_SUGGESTION_COLOR)
                const gradientColors = AI_MATERIAL_REFLECTED_GRADIENT_COLORS[selectedMaterialType]
                const gradientColor = yield call(
                    colorTool.createReflectedGradient,
                    gradientColors.outerColor,
                    gradientColors.innerColor,
                    aiDrawable.data.aiSuggestion.coordinates
                )
                const selectedSuggestionIds = yield select(selectedAiSuggestionIds)

                const idAlreadySelected = aiSuggestionPayload.payload.isGroupVisible
                    ? false
                    : selectedSuggestionIds.includes(aiDrawable.data.aiSuggestion.id)

                if (idAlreadySelected || aiSuggestionPayload.payload.isGroupVisible === false) {
                    aiDrawable.strokeColor = gradientColor
                    yield put(deleteSelectedAiSuggestionIds(selectedAISuggestionID))

                    if (selectedSuggestionIds.length === 1) {
                        yield put(updateSelectedMaterialType(null))
                        yield fork(handleCloseMaterialForm)
                    }
                } else {
                    aiDrawable.strokeColor = selectedColor
                    yield put(setSelectedAiSuggestionIds(selectedAISuggestionID))

                    if (selectedSuggestionIds.length === 0) {
                        // hide saved drawables and ai suggestions of different types
                        const visibilityTest = (item: paper.Item): boolean => {
                            if (isSnappingEnabled && item.visible) return true

                            const isAISuggestion = item.data?.aiSuggestion?.id !== undefined

                            if (!isAISuggestion) {
                                return false
                            }

                            return (
                                selectedMaterialType === item.data?.aiSuggestion?.type &&
                                !hiddenAIIds.includes(item.data.aiSuggestion?.id)
                            )
                        }

                        yield call(workspaceTool.hideDrawablesWithCondition, visibilityTest)

                        // prepare from data
                        yield fork(getAndActivateFormByType, selectedMaterialType, FormTypes.materials)
                        const suggestionSettings: GeneralDrawableSettings = aiSuggestions.find(
                            (suggestion) => suggestion.id === selectedAISuggestionID
                        ).settings

                        yield put(setAiSuggestionSettings(suggestionSettings))
                    }
                }
            }
        } else if (aiSuggestionPayload.payload.aiDrawableID) {
            yield put(createDrawableLocation(aiSuggestionPayload.payload.aiDrawableID))
        }
    } catch (error) {
        yield call(console.error, error)
    }
}

export function* watchForUpdateSelectedAISuggestion() {
    yield takeLatest(updateSelectedAISuggestion.type, handleUpdateSelectedAISuggestion)
}
