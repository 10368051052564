import axios, { CancelTokenSource } from 'axios'
import { v4 as uuidv4 } from 'uuid'

import {
    createDrawablePending,
    createDrawableSuccess,
    fetchDrawablePending,
    fetchDrawableSuccess,
    fetchMappingError,
    fetchMappingPending,
    fetchMappingSuccess,
    fetchOpeningGroupPending,
    fetchOpeningGroupsSuccess,
    fetchProjectSuccess,
    setErrorState,
    setFloorDrawables,
    setPendingState,
    updateJoistLinesSuccess,
} from '../actions/drawable'
import { toggleDisableSelectToolState } from '../imup/sagas/effects/changeSelectToolState'
import { IMappings } from '../imup/slices/documents'
import { GENERAL_CACHE } from '../imup/slices/forms'
import { BasicFloorMapping } from '../imup/slices/mappings'
import { IMUP2DDrawableCutout, MeasurementsToUpdate } from '../imup/types'
import { ActiveDrawable, Coordinate, OpeningGroupAPI } from '../models/activeDrawable'
import { Comment, CommentStatus, CommentType } from '../models/comments'
import { DocumentMapping } from '../models/documentMapping'
import { DropdownFieldOption } from '../models/dropdownOptions'
import { Project } from '../models/project'
import { Building } from '../models/projectBuilding'
import { Region } from '../models/region'
import { IUpdatedRegionResponse } from '../models/updatedRegionResponse'
import { UpdateOpeningObject } from '../shared/components/calibrate-tool/calibrate-tool'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import IndexableObject from '../shared/constants/general-enums/indexableObject'
import { HTTPStatusCode } from '../shared/constants/http-status-codes'
import { PROJECT_STATUSES_ENUM } from '../shared/constants/project-list.constants'
import { PROJECT_TYPES_ENUM } from '../shared/constants/project-type.constants'
import { PROJECT_STATUS_NAME } from '../shared/constants/projectStatusName'
import { AppDispatch } from '../stores'
import { getProjectById } from './projects-api'

const localStoragePortalKey = 'portal_auth_token'

const getProjectByPortalNumber = (portalNumber: number, projectType?: PROJECT_TYPES_ENUM) => {
    return (dispatch) => {
        return axios
            .get<void, Project>(`projectbyportalnumber/${portalNumber}`, { params: { project_type: projectType } })
            .then((res) => {
                dispatch(fetchProjectSuccess(res))
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
    }
}

const getProjectByIdAndDispatchAction = (id: number) => {
    return (dispatch) => {
        return getProjectById(id)
            .then((res) => {
                dispatch(fetchProjectSuccess(res))
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
    }
}

// This is only used for the copy-3d-project tool. PLEASE DO NOT REMOVE!
const getProjectStatusByPortalNumber = (portalNumber: number) => {
    return axios
        .get<void, Project>(`projectbyportalnumber/${portalNumber}`)
        .then(({ status }) => {
            return {
                status,
            }
        })
        .catch(() => {
            return {
                status: 'Error - details not found',
            }
        })
}

const updateProjectByPortalNumber = (
    portalNumber: number,
    projectType: PROJECT_TYPES_ENUM,
    newStatus: PROJECT_STATUSES_ENUM,
    newProjectStatusName: PROJECT_STATUS_NAME,
    setProject?: (project: Project | undefined) => void
) => {
    return axios
        .put<void, Project>(`projectbyportalnumber/${portalNumber}`, {
            status: newStatus,
            projectStatusName: newProjectStatusName,
            project_type: projectType,
        })
        .then((res) => {
            setProject && setProject(res)
        })
}

const getProjectMappingByFloor = (projectId: number): ((dispatch: AppDispatch) => Promise<void | IMappings>) => {
    return (dispatch: AppDispatch) => {
        dispatch(fetchMappingPending())

        return axios
            .get<void, IMappings>(`project/${projectId}/floors/document-mappings`)
            .then((res) => {
                dispatch(fetchMappingSuccess(res))

                return res
            })
            .catch((error) => {
                dispatch(fetchMappingError(error))
            })
    }
}

const createOpening = (
    projectId: string | undefined,
    floor: string,
    opening: ActiveDrawable,
    listOfOpenings: ActiveDrawable[]
) => {
    // Commented out temporary until gateway portion of BLUEPRINTS-3661 is completed
    // if (opening.opening_locations[1]) { // elevation coordinates were provided
    //     opening.settings.visible = "Yes"
    // }
    return (dispatch) => {
        dispatch(createDrawablePending())

        return axios
            .post<void, ActiveDrawable>(`project/${projectId}/floor/${floor}/opening`, opening)
            .then((loadedDrawable) => {
                dispatch(createDrawableSuccess(loadedDrawable))
                listOfOpenings.push(loadedDrawable)
                dispatch(setFloorDrawables(listOfOpenings))

                return loadedDrawable
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
    }
}

const updateOpening = (
    projectId: string | number | undefined,
    floor: string,
    opening: UpdateOpeningObject,
    openingId: string
) => {
    // Commented out temporary until gateway portion of BLUEPRINTS-3661 is completed
    // if (opening.opening_locations[1]) { // elevation coordinates were provided
    //     opening.settings.visible = "Yes"
    // }
    return (dispatch) => {
        dispatch(createDrawablePending())

        return axios
            .put<void, ActiveDrawable>(`project/${projectId}/floor/${floor}/opening/${openingId}`, opening)
            .then((loadedDrawable) => {
                dispatch(createDrawableSuccess(loadedDrawable))

                return loadedDrawable
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
    }
}

const updateOpeningGroup = (
    projectId: string | number | undefined,
    openingGroup,
    openingGroupId: string
): Promise<OpeningGroupAPI> => {
    return axios.put<void, OpeningGroupAPI>(`project/${projectId}/opening_group/${openingGroupId}`, openingGroup)
}

// Unable to specify the return type due to unknown data.
// OpeningGroupAPI | OpeningGroup
const updateJoistLines = (projectId: number, openingGroupId: number) => {
    return (dispatch) => {
        dispatch(setPendingState())
        dispatch(toggleDisableSelectToolState())

        return axios
            .get(`project/${projectId}/openinggroup/${openingGroupId}/joistlines`)
            .then((res) => {
                dispatch(updateJoistLinesSuccess(res))

                return res
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
            .finally(() => {
                dispatch(toggleDisableSelectToolState())
            })
    }
}

/**
 *
 * @param projectId project id
 * @param floor floor_hash of the active floor
 * @param openingId id of a draggable or deleted opening
 * @param index destination index of a draggable opening
 */
const updateOpeningLabels = (projectId: number, floor: string, openingId: number, index: number) => {
    return (dispatch) => {
        dispatch(createDrawablePending())

        return axios
            .put<void, ActiveDrawable[]>(`project/${projectId}/floor/${floor}/opening/${openingId}/index/${index}`)
            .then((res) => {
                dispatch(setFloorDrawables(res))

                return res
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
    }
}

const updateAiData = (projectId: number, chunkId: number, aiData: string) => {
    const data = {
        ai_classification_data: aiData,
        document_chunk_id: chunkId,
    }

    return axios.put(`project/${projectId}/ai_data`, data)
}

const updateAutomatedObject = (id: string, isDeleted: boolean, isModified: boolean) => {
    return axios.put('automated-object/update', { id, isModified, isDeleted })
}

const deleteOpening = (projectId: number, floor: string, openingId: number) => {
    return (dispatch) => {
        return axios
            .delete<void, ActiveDrawable[]>(`project/${projectId}/floor/${floor}/opening/${openingId}`)
            .then((res) => {
                dispatch(setFloorDrawables(res))

                return res
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
    }
}

const getFloorOpenings = (projectId: number, floor: string) => {
    return (dispatch) => {
        dispatch(fetchDrawablePending())

        return axios
            .get<void, ActiveDrawable[]>(`project/${projectId}/floor/${floor}/openings`)
            .then((res) => {
                dispatch(fetchDrawableSuccess(res))
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
    }
}

/**
 * Returns project openings of the specified type
 * @param projectId project id
 * @param type opening type ('window', 'patio' etc. )
 */
const getOpeningsByType = (projectId: number, type: DRAWABLE_TYPES): Promise<ActiveDrawable[]> => {
    return axios.get<void, ActiveDrawable[]>(`project/${projectId}/openingsByType/${type}`)
}

/**
 * Returns all openings with specified project_id
 * @param projectId Digitizer project id
 */
const getOpeningsPerProject = (projectId: number): Promise<ActiveDrawable[]> => {
    return axios.get<void, ActiveDrawable[]>(`project/${projectId}/openings`)
}

const getAllFloors = (): Promise<BasicFloorMapping[]> => {
    return axios.get<void, BasicFloorMapping[]>(`floors`)
}

const getOpeningGroups = (projectId: number, modelId: string | null = null) => {
    return (dispatch) => {
        dispatch(fetchOpeningGroupPending())

        return fetchOpeningGroupsByProjectId(projectId, modelId)
            .then((openGroupsAPI) => {
                dispatch(fetchOpeningGroupsSuccess(openGroupsAPI))

                return openGroupsAPI
            })
            .catch((error) => {
                dispatch(setErrorState(error))
            })
    }
}

const fetchOpeningGroupsByProjectId = (projectId: number, modelId: string | null = null) => {
    return axios
        .get<void, OpeningGroupAPI[] | { error: string }>(`project/${projectId}/opening_groups?model_id=${modelId}`)
        .then((openGroupsAPI) => {
            if ('error' in openGroupsAPI) {
                throw openGroupsAPI.error
            }

            return openGroupsAPI
        })
}

const completeTakeoff = (projectId: number) => {
    return (dispatch) => {
        dispatch(setPendingState())
        const data = {
            token: 'Bearer ' + window.localStorage.getItem(localStoragePortalKey),
        }

        return axios.post(`project/${projectId}/takeoff/complete`, data).catch((error) => {
            dispatch(setErrorState(error))
        })
    }
}

const completeMarkup = (projectId: number, outputFilePrefix: string, doNotSendToPortal?: boolean) => {
    const data: {
        token: string
        outputFilePrefix?: string
        doNotSendToPortal?: boolean
    } = {
        token: 'Bearer ' + window.localStorage.getItem(localStoragePortalKey),
    }

    if (outputFilePrefix) {
        data.outputFilePrefix = outputFilePrefix
    }
    if (doNotSendToPortal) {
        data.doNotSendToPortal = doNotSendToPortal
    }

    return axios.post(`project/${projectId}/markup/complete`, data)
}

const getDocumentMappings = (projectId: number) => {
    return axios.get(`project/${projectId}/document-mappings`)
}

const createComment = (projectId: number, type_id: number, value: string) => {
    const data = { type_id, value }

    return axios.post(`project/${projectId}/createComment`, data)
}

const getComments = (projectId: number): Promise<Comment[]> => {
    return axios.get<void, Comment[]>(`project/${projectId}/comments`)
}

const getCommentStatuses = (): Promise<CommentStatus[]> => {
    return axios.get<void, CommentStatus[]>('commentStatuses')
}

const getCommentTypes = (): Promise<CommentType[]> => {
    return axios.get<void, CommentType[]>('commentTypes')
}

/**
 * Update comment by comment id
 * @param commentId comment id
 * @param value optional comment value
 * @param type_id optional comment type
 * @param status_id optional comment status
 */
const updateComment = (commentId: number, value?: string, type_id?: number, status_id?: number) => {
    return axios.put(`comment/${commentId}`, { value, type_id, status_id })
}

// Unable to specify the return type due to unknown data.
// DropdownOption[] | DropdownFieldOption[] | MultiCheckboxOptions[]
const getFormSelections = (
    sectionTypeNameStartsWith: string,
    basic: string | boolean = 'basic',
    advanced: 'advanced' | boolean = 'advanced',
    all: string | boolean = 'all'
) => {
    return axios.get<void, DropdownFieldOption[]>(
        `selections/${sectionTypeNameStartsWith}?access_levels=["${basic}", "${advanced}", "${all}"]`
    )
}

/**
 * Get the form schema type from the backend
 * hydrated with selections in the backend
 * @param type the form schema type
 * @returns
 */
const getFormSchema = (type: string, buildingId: string) => {
    return axios.get(`schemas/${type}${buildingId !== GENERAL_CACHE ? '/' + buildingId : ''}`)
}

/**
 * Update the properties of a singular building
 * @param building Building with updated properties to commit
 */
const updateBuilding = (building: Building) => {
    return axios.put(`project/${building.project_id}/building/${building.id}`, { building })
}

/**
 * Create a new building on a given project
 * @param project_id ID of the project
 * @param name
 */
const createBlankBuilding = (project_id: number, name: string): Promise<Building[]> => {
    return axios.post<void, Building[]>(`building`, {
        project_id,
        buildings: [
            {
                name,
                rcm_id: uuidv4(),
                settings: {
                    framing_settings: {
                        rcm_id: uuidv4(),
                    },
                    exterior_settings: {
                        rcm_id: uuidv4(),
                    },
                },
            },
        ],
    })
}

const deleteProjectBuilding = (buildingId: Building['id']) => {
    return axios.delete(`building/${buildingId}`)
}

/**
 * Create a new region on the current project
 * @param project_id ID of the current project
 * @param region The region to be created
 */
const createRegion = (project_id: number, region: Omit<Region, 'id'>): Promise<Region> => {
    return axios.post<void, Region>(`project/${project_id}/document-chunk/${region.document_chunk_id}/region`, region)
}

/**
 * Update the properties of a single region
 * @param project_id ID of the current project
 * @param region_id
 * @param region The region to be updated
 */
const updateRegion = (
    project_id: number,
    region_id: number,
    region: Omit<Region, 'id'>
): Promise<IUpdatedRegionResponse> => {
    return axios.put<void, IUpdatedRegionResponse>(
        `project/${project_id}/document-chunk/${region.document_chunk_id}/region/${region_id}`,
        region
    )
}

/**
 * Deletes a region
 * @param regionId {number} the ID of the region to delete
 * @returns the api result either string boolean or json error
 */
const deleteRegion = (regionId: number): Promise<string> => {
    return axios.delete<void, string>(`region/${regionId}`)
}

/**
 * Create a new document mapping on the current project
 * @param project_id ID of the current project
 * @param documentMapping The document mapping to be created
 */
const createDocumentMapping = (project_id: number, documentMapping: DocumentMapping): Promise<DocumentMapping[]> => {
    return axios.post<void, DocumentMapping[]>(`project/${project_id}/document-mapping`, documentMapping)
}

/**
 * Update an existing document mapping on the current project
 * @param project_id ID of the current project
 * @param documentMapping The document mapping to be updated
 */
const updateDocumentMapping = (project_id: number, documentMapping: DocumentMapping) => {
    return axios.put(`project/${project_id}/document-mapping/${documentMapping.id}`, documentMapping)
}

const updateOpeningLocation = (projectId: number, openingLocationId: number, newAdditionalData: IndexableObject) => {
    return axios.put(`project/${projectId}/opening_location/${openingLocationId}`, {
        additional_data: newAdditionalData,
    })
}

function makePostRequest(url: string, timeout: number, cancelSource?: CancelTokenSource) {
    const requestPromise = cancelSource ? axios.post(url, {}, { cancelToken: cancelSource.token }) : axios.post(url, {})

    const timeoutPromise = new Promise((_, reject) => {
        if (cancelSource) {
            setTimeout(() => {
                const customCancel = {
                    message: `Request took more than ${timeout / 1000} seconds`,
                    customStatus: HTTPStatusCode.REQUEST_TIMEOUT,
                }

                cancelSource.cancel(JSON.stringify(customCancel))
                reject(new Error('Request timed out'))
            }, timeout)
        }
    })

    return cancelSource ? Promise.race([requestPromise, timeoutPromise]) : requestPromise
}

const getTakeoffData = async (projectId: number, timeout = 10000, cancelSource?: CancelTokenSource) => {
    try {
        return await makePostRequest(`project/${projectId}/takeoff`, timeout, cancelSource)
    } catch (error) {
        console.error('Error:', error)
    }
}

const bulkUpdateOpeningLocationData = async (
    projectId: number,
    body: {
        opening_id: number
        opening_location_id: number
        coordinates: Coordinate[]
        measurements: MeasurementsToUpdate
        cutouts?: IMUP2DDrawableCutout[]
        region_id?: number | null
    }[]
) => {
    const res = await axios.put(`project/${projectId}/opening-locations/update`, {
        openings_locations_measurements_cutouts: body,
    })

    return res
}

export {
    bulkUpdateOpeningLocationData,
    completeMarkup,
    completeTakeoff,
    createBlankBuilding,
    createComment,
    createDocumentMapping,
    createOpening,
    createRegion,
    deleteOpening,
    deleteProjectBuilding,
    deleteRegion,
    fetchOpeningGroupsByProjectId,
    getAllFloors,
    getComments,
    getCommentStatuses,
    getCommentTypes,
    getDocumentMappings,
    getFloorOpenings,
    getFormSchema,
    getFormSelections,
    getOpeningGroups,
    getOpeningsByType,
    getOpeningsPerProject,
    getProjectByIdAndDispatchAction,
    getProjectByPortalNumber,
    getProjectMappingByFloor,
    getProjectStatusByPortalNumber,
    getTakeoffData,
    updateAiData,
    updateAutomatedObject,
    updateBuilding,
    updateComment,
    updateDocumentMapping,
    updateJoistLines,
    updateOpening,
    updateOpeningGroup,
    updateOpeningLabels,
    updateOpeningLocation,
    updateProjectByPortalNumber,
    updateRegion,
}
