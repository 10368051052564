import '@babylonjs/core/Collisions/collisionCoordinator'
import '@babylonjs/core/Culling/ray'

import { ReactElement } from 'react'
import { Matrix } from '@babylonjs/core/Maths/math.vector'
import { AbstractMesh } from '@babylonjs/core/Meshes/abstractMesh'
import { Mesh } from '@babylonjs/core/Meshes/mesh'
import { Scene } from '@babylonjs/core/scene'
import { FormValidation, UiSchema } from '@rjsf/core'
import { KeyHandler } from 'hotkeys-js'
import { JSONSchema7 } from 'json-schema'
import Paper from 'paper'
import { Observable } from 'rxjs'

import { ActiveDrawable, Coordinate, DrawableLocation, OpeningAPI, OpeningGroup } from '../models/activeDrawable'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { ActiveFloor } from '../models/activeFloor'
import { Project } from '../models/project'
import { Region } from '../models/region'
import { DRAWABLE_TYPES, DRAWING_TYPES } from '../shared/constants/drawable-types'
import IndexableObject from '../shared/constants/general-enums/indexableObject'
import { RootState } from '../stores'
import AbstractManager from './lib/managers/AbstractManager'
import { BabylonManager } from './lib/managers/BabylonManager'
import PaperManager from './lib/managers/PaperManager'
import { SNAPPING_SIDE } from './utils/constants'

export const ROOF_STOREY_NAME = 'Level Roof'
export const SHRUB_PARTIAL_MATERIAL_NAME = 'Shrub'
export const BUSH_PARTIAL_MATERIAL_NAME = 'Bush'
export const JOIST_LINES_SUFFIX = 'joist-line'
export const JOIST_LINE_DRAWABLE_NAME = 'joist_line'
export const IDENTITY_MATRIX: Matrix = Matrix.Identity()

export const SAVING_MESSAGE = 'Saving changes'
export const SAVING_CHANGES_ERROR_MESSAGE = 'Error saving changes'
export const PASTING_MATERIAL_MESSAGE = 'Pasting Material'
export const ERROR_PASTING_MATERIAL_MESSAGE = 'Error pasting Material'

export const ROUNDED_CORNER_SIZE_IN_PX = 16

/** Reasanoble timer to allow user to read the short message provided
 *  after update coordinates api is called */
export const TOOLBAR_MESSAGE_TIMER = 2000
export const SAGA_THROTTLE_TIMER = 2000

export const CUSTOM_COLOR = 'custom'
export const HIGHLIGHT_COLOR_OPTIONS = {
    green: '#89fc80',
    yellow: '#ffd366',
    red: '#ff9e95',
    blue: '#66a0f6',
    black: '#5c5c5c',
    custom: CUSTOM_COLOR,
}

export const DRAWABLE_UNITS_OF_MEASURE = {
    area: 'SQFT',
    line: 'LF',
    count: 'EA',
}

export const HIGHLIGH_LINEAR_COLOR_GRADIENT_ACCENTS = {
    [HIGHLIGHT_COLOR_OPTIONS.green]: '#c2fd88',
    [HIGHLIGHT_COLOR_OPTIONS.blue]: '#88dafd',
    [HIGHLIGHT_COLOR_OPTIONS.red]: '#f8caa0',
    [HIGHLIGHT_COLOR_OPTIONS.yellow]: '#fff6a3',
}

export const STANDARD_INPUT_DEBOUNCE_IN_MS = 300
export const LONG_INPUT_DEBOUNCE_IN_MS = 1000

export const IMUP_DRAWABLE_COLORS: Record<string, { hexCode: string; rgbTag: string }> = {
    window: {
        hexCode: '#E88649',
        rgbTag: 'rgba(232,134,73,1)',
    },
    section: {
        hexCode: '#E88649',
        rgbTag: 'rgba(232,134,73,1)',
    },
    le_window: {
        hexCode: '#E88649',
        rgbTag: 'rgba(232,134,73,1)',
    },
    patio: {
        hexCode: '#2DD8CE',
        rgbTag: 'rgba(45,216,206,1)',
    },
    area: {
        hexCode: '#2DD8CE',
        rgbTag: 'rgba(45,216,206,1)',
    },
    interiorDoor: {
        hexCode: '#AA4499',
        rgbTag: 'rgba(170,68,153,1)',
    },
    entryDoor: {
        hexCode: '#785EF0',
        rgbTag: 'rgba(120,94,240,1)',
    },
    siding: {
        hexCode: '#EF476F',
        rgbTag: 'rgba(239,71,111,1)',
    },
    vent: {
        hexCode: '#000000',
        rgbTag: 'rgba(0,0,0,0)',
    },
    point: {
        hexCode: '#2D7DD8',
        rgbTag: 'rgba(45,125,216,1)',
    },
    ai: {
        hexCode: '#2D7DD8',
        rgbTag: 'rgba(45,125,216,1)',
    },
    le_door: {
        hexCode: '#2D7DD8',
        rgbTag: 'rgba(45,125,216,1)',
    },
    le_unknown: {
        hexCode: '#2D7DD8',
        rgbTag: 'rgba(45,125,216,1)',
    },
    floorLine: {
        hexCode: '#427BCC',
        rgbTag: 'rgba(66,123,204,1)',
    },
    roofing: {
        hexCode: '#FFD166',
        rgbTag: 'rgba(255,209,102,1)',
    },
    trim: {
        hexCode: '#06D6A0',
        rgbTag: 'rgba(6,214,160,1)',
    },
    corner: {
        hexCode: '#118AB2',
        rgbTag: 'rgba(17,138,178,1)',
    },
    post_wrap: {
        hexCode: '#073B4C',
        rgbTag: 'rgba(7,59,76,1)',
    },
    sidelight: {
        hexCode: '#785EF0',
        rgbTag: 'rgba(120,94,240,1)',
    },
    le_joist: {
        hexCode: '#437A87',
        rgbTag: 'rgba(67,122,135,1)',
    },
    'i-joist': {
        hexCode: '#7a7d92',
        rgbTag: 'rgb(122, 125, 146)',
    },
    ceiling_joist: {
        hexCode: '#e6f356',
        rgbTag: 'rgb(230, 243, 86)',
    },
    floor_joist: {
        hexCode: '#c80fae',
        rgbTag: 'rgb(200, 15, 174)',
    },
    sleeper_joist: {
        hexCode: '#30b050',
        rgbTag: 'rgb(48, 176, 80)',
    },
    [JOIST_LINE_DRAWABLE_NAME]: {
        hexCode: '#000000',
        rgbTag: 'rgb(0, 0, 0)',
    },
}

export const EDGE_TUBE_RADIUS = 0.15
export const EDGE_TUBE_RADIUS_LARGE = 0.25

// ******************** ENUMS ********************

export enum DEFAULT_BUNDLE_NAMES {
    APPLIANCES = 'APPLIANCES',
    FRAMING = 'FRAMING',
    EXTERIOR = 'EXTERIOR',
    ROOF_FRAMING = 'ROOF FRAMING',
    POST_AND_BEAMS = 'POSTS & BEAMS',
    HARDWARE = 'HARDWARE',
    FOUNDATION_MATERIAL = 'FOUNDATION MATERIAL',
    MILLWORK = 'MILLWORK',
    SCREEN_PORCH = 'SCREEN PORCH',
    MAIN_FLOOR_DECK = 'MAIN FLOOR DECK',
    DECK = 'DECK',
    EMPTY = '',
}

export enum ModelType {
    WALL = 'WALL',
    STAIR = 'STAIR',
    STRUCTURAL_FRAMING = 'Structural Framing',
    COLUMN = 'COLUMN',
    DOOR = 'DOOR',
    WINDOW = 'WINDOW',
    SLAB = 'SLAB',
    ROOF = 'ROOF',
    STOREY = 'STOREY',
    SPACE = 'SPACE',
    TEXTURE_ELEMENT = 'TEXTUREELEMENT',
    GARAGE_DOOR = 'GARAGEDOOR',
    ENTRY_DOOR = 'ENTRYDOOR',
    WINDOW_GLASS = 'WINDOWGLASS',
    WINDOW_FRAME = 'WINDOWFRAME',
    DOOR_GLASS = 'DOORGLASS',
    DOOR_FRAME = 'DOORFRAME',
    DOOR_SILL = 'DOORSILL',
    DOOR_GRILLS = 'DOORGRILLS',
    DOOR_STRUCTURE = 'DOORSTRUCTURE',
    GRASS = 'GRASS',
    BUILDING_ELEMENT_PROXY = 'BUILDINGELEMENTPROXY',
    IFC_COLUMN = 'IfcColumn',
    IFC_COVERING = 'IfcCovering',
    IFC_RAILING = 'IfcRailing',
    IFC_STAIR_FLIGHT = 'IfcStairFlight',
    SHRUB = 'SHRUB',
    BEAMS = 'IfcBeam',
    IFC_FURNITURE_ELEMENT = 'IfcFurnishingElement',
    GABLE = 'GABLE',
    EAVE = 'EAVE',
    FLASHING = 'FLASHING',
    MYBUILD = 'MYBUILD',
    RAILING = 'RAILING',
    CEILING = 'CEILING',
    CORNER = 'CORNER',
    JUNCTION = 'JUNCTION',
}

export enum FloorJunctionTypes {
    SIDING = 'SIDING',
    FLOOR = 'FLOOR',
}

/** view mode enumerative type, can be 2D markup, 3d markup, or other in future */
export enum VIEW_MODE {
    Markup2D = 'MARKUP2D',
    Markup3D = 'MARKUP3D',
    Markup2DFor3D = 'MARKUP2DFOR3D',
}

/** Mouse click codes - numeric enum value corresponds to event code for button */
export enum MouseButtonCodes {
    Left = 0,
    Middle = 1,
    Right = 2,
}

// these `buttons` codes are different than than the `button` code. 4 = scroll click, 2 = right click. See: https://www.w3schools.com/jsref/event_buttons.asp
export enum MouseButtonsCodes {
    Right = 2,
    Middle = 4,
}

export enum Cursors {
    AUTO = 'auto',
    GRAB = 'grab',
    GRABBING = 'grabbing',
    CROSSHAIR = 'crosshair',
    MOVE = 'move',
    ROTATE = 'ew-resize',
}

export enum TransformationTypes {
    MOVING,
    SCALING,
    ROTATING,
}

export enum MaterialOptionsType {
    PBR,
    FUR,
    DRAWABLE_COLORS,
}

export enum PBRMaterialNames {
    Roof = 'ROOF',
    Siding = 'SIDING',
    Glass = 'GLASS',
}

/** Paper raster smoothing values */
export enum SmoothingValues {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high',
    OFF = 'off',
}

export enum ArrowDirection {
    Up = 'UP',
    Right = 'RIGHT',
    Down = 'DOWN',
    Left = 'LEFT',
}

export enum LineType {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal',
    DIAGONAL = 'diagonal',
}

export enum Side {
    LEFT = 'left',
    RIGHT = 'right',
}

/**
 * These values provide a real world scale to items. Use these to ensure consistent sizing between projects regardless of blueprint resolution.
 * The values here represent a proportion of 1 foot. e.g. 1/3 = 4 inches
 */
export enum ItemScale {
    COMMENT = 0.1, // approx 1 ft with the pin icon in use
    LINE = 1 / 3, // pin the wall width to be 4" (1/3 of 1')
    POINT = 0.005, // Points should be a fraction of the maximum dimension of the image
    HANDLE = 0.01, // Handles should be a fraction of the maximum dimension of the image
}

export enum CameraPositions {
    TOP_DOWN = 'TOP',
    RIGHT_SIDE = 'RIGHT',
    BOTTOM_UP = 'BOTTOM',
    LEFT_SIDE = 'LEFT',
    FRONT = 'FRONT',
    BACK = 'BACK',
}

// ******************** CONSTANTS ********************
export const FPS = 60

// Round to the nearest 1/64
// as according to caleb as
// of mid sep 2022 that
// will meet requirements
export const MEASUREMENT_PRECISION: number = 1 / 64

export enum REGION_ENUMS {
    TYPE = 'REGION',
}

export enum TOOL_TYPE_ENUMS {
    FLOOR_LEVEL_BREAK_LINE = 'FLOOR_LEVEL_BREAK_LINE',
    MEASUREMENT = 'MEASUREMENT',
}

export const MODEL_TYPES_TO_LOAD_IN_VIEWER: ModelType[] = [
    ModelType.STOREY,
    ModelType.STRUCTURAL_FRAMING,
    ModelType.WALL,
    ModelType.WINDOW_FRAME,
    ModelType.WINDOW_GLASS,
    ModelType.DOOR_GLASS,
    ModelType.DOOR_FRAME,
    ModelType.DOOR_GRILLS,
    ModelType.DOOR_SILL,
    ModelType.DOOR_STRUCTURE,
    ModelType.ROOF,
    ModelType.SLAB,
    ModelType.BUILDING_ELEMENT_PROXY,
    ModelType.IFC_COLUMN,
    ModelType.IFC_COVERING,
    ModelType.IFC_RAILING,
    ModelType.IFC_STAIR_FLIGHT,
    ModelType.RAILING,
    ModelType.CEILING,
    ModelType.COLUMN,
    ModelType.STAIR,
    ModelType.TEXTURE_ELEMENT,
    ModelType.IFC_FURNITURE_ELEMENT,
]

// ******************** TYPES ********************

export type FormProperties<T = OpeningGroup['settings']> = {
    uiSchema: UiSchema
    onValidate: (formData: any, errors: FormValidation) => any | void
    onSubmit: (settings: T, x: T) => T | void
    prepareDrawables?: (input: {
        drawables: ActiveDrawable[]
        newSettings: GeneralDrawableSettings
        scaleFactor: number
        dpi: number | null
        pdfScale: number
        xCalibrationFactor: number
        yCalibrationFactor: number
        regions: Region[]
    }) => ActiveDrawable[]
}

export type HydratedForm = FormProperties & { schema: JSONSchema7 }

/** Vectors and Coordinates */
export type Vector2D = [number, number]
export type Vector3D = [number, number, number]
export type Coordinates2D = Vector2D[]
export type Coordinates3D = Vector3D[]
export type IModelTranslationInfo = {
    x: number
    y: number
    z: number
}

export type RevitIdToConfigIdMap = Record<string, string | null>

/** If not a DOM element, ImageSource must be a valid url or data url string. */
export type ImageSource = HTMLImageElement | HTMLCanvasElement | string

/** If not a DOM element, SVGSource must be a valid url or data url string. */
export type SVGSource = SVGElement | string

/** Custom Hook return types */

export type BabylonReact = {
    manager: BabylonManager | null
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null>
}

export type PaperReact = {
    canvasRef: React.MutableRefObject<HTMLCanvasElement | null>
    manager: PaperManager | null
}

/** 2D Drawable types */

// eslint-disable-next-line
export type Drawables = Array<ActiveDrawable> // need type def for this from OG store

export type PaperDrawables = {
    drawables: Drawables
}

export type PaperDrawingContext = {
    manager: PaperManager
    drawableLocations: IMUP2DDrawableLocation[]
    activeFloor: ActiveFloor
    openingGroups: OpeningGroup[]
}

/** 2D API Response Interim Representations **/

export type OpeningWithGroupId = OpeningAPI & { groupId: number }

export type LocationWithGroupId = DrawableLocation & {
    groupId: number
    drawableType: DRAWABLE_TYPES
    drawable: OpeningAPI
    lengthOfOpeningLocations: number
    settings: Record<string, any>
}

/** IMUP State */

export type IMUPState = RootState['IMUP']
export type IMUPKey = keyof IMUPState
export type IMUPObject<K extends IMUPKey> = IMUPState[K]
export type DraftIMUPObject<K extends IMUPKey> = Partial<IMUPObject<K>>

/** Tools, by name, held by a tool box */
export type Tools = Record<string, ITool>

/** Manager Configurations */

export type ManagerConfig = {
    mediator: IStateMediator
}

export type BabylonManagerConfig = ManagerConfig & {
    scene: Scene
}

export type PaperManagerConfig = ManagerConfig & {
    paper: typeof Paper
}

/** Tool Box Configuration */

export type ToolBoxConfig = {
    mediator: IStateMediator
    tools?: string[]
}

/** Tool types */
export type BabylonPredicateFn = (mesh: AbstractMesh) => boolean

/** Keyboard shortcut */
export type Shortcut = {
    keybind: ShortcutKeybind
    withModifier?: boolean
    actions: {
        mode: VIEW_MODE
        action: KeyHandler
    }[]
}

export type ShortcutKeybind = {
    primary: string
    secondary?: string // optional second hotkey
    modifier?: string // optional modifier (e.g. 'shift' -> 'shift+right': increased move speed vs just 'right' ')
}

export type BabylonToolBoxConfig = ToolBoxConfig & {
    scene: Scene
}

export type PaperToolBoxConfig = ToolBoxConfig & {
    paper: typeof Paper
}

export type BabylonToolConfig = BabylonToolBoxConfig

export type PaperToolConfig = PaperToolBoxConfig

/** 2D/3D selections and mapping */

export type ScrollStepPayload = {
    scrollStep: number
}

export type TwoDSelectedItem = {
    locationId: number
    openingId: number
    groupId: number
    type?: string
}

export type EditHandleData = {
    vertex: boolean
    parent: paper.Item
    position: number
    ignoreHitFilter: boolean
    cutoutIndex?: number
}

export type MidpointData = {
    midpoint: boolean
    parent: paper.Item
    curve: paper.Curve
    location: paper.CurveLocation
    index: number
    ignoreHitFilter: boolean
}

export type ThreeDToTwoDRecord = Record<string, TwoDSelectedItem[]>

export type BaseMaterialOptions = {
    name: PBRMaterialNames
    color?: string
    textureUrl?: string
    textureWidth?: number
    textureHeight?: number
    textureUrlHasAlpha?: boolean
}

type PBRExtensions = {
    reflectivityColor: string | null
    reflectivityTextureUrl: string | null
    ambientColor: string | null
    ambientTextureUrl: string | null
    emissiveColor: string | null
    emissiveTextureUrl: string | null
    roughness: number | null
    normalTextureUrl?: string | null
    metallic: number | null
    metallicTextureUrl: string | null
    reflectionColor: string | null
    alpha?: number
    parallaxScaleBias: number
    microSurface: number
    indexOfRefraction: number
    useMicroSurfaceFromReflectivityMapAlpha: boolean
    linkRefractionWithTransparency: boolean
    refractionTexture: boolean
    renderMode: string
}

export type PBRMaterialOptions = BaseMaterialOptions & PBRExtensions

export type JunctionRawInput = {
    floorJunctions: ThreeDElementsResponse | null
    roofJunctions: ThreeDElementsResponse | null
}

export type CornersMeshInputData = IBabylonLineWithMetadata & {
    intersections: ICornerIntersection[]
}

export type ThreeDElementsResponse = Record<string, IThreeDElementsRaw>

export type OpeningsResponse = Record<string, IOpeningRaw>

export type ConfigurationQuestionAndAnswersResponse = {
    questions: Record<string, string>
    answers: Record<string, string>
    phaseAndDesignOptions: Record<string, PhaseAndDesignOption[]>
    multiOptionElements?: Record<string, string[]>
}

type PhaseAndDesignOption = {
    questionId: string
    answerId: string
}

export type JoistLinesRawData = {
    coordinates: [number, number][]
    locationId: number
    openingId: number
    groupId: number
}

export type CreateDrawableGroupActionInput = {
    project: Project
    type: DRAWABLE_TYPES
    settings: Record<string, any>
}

// ******************** INTERFACES ********************

/**
 * IMUP2DDrawableLocation
 * This DrawableLocation includes all relevant info to render, style and
 * select a geometry in 2D IMUP
 */
export interface IMUP2DDrawableLocation {
    opening_location_id: number
    opening_group_id: number
    drawable_id: number
    document_chunk_id: number
    drawing_type: DRAWABLE_TYPES
    coordinates: Coordinate[]
    settings: Record<string, any>
    shapeType: DRAWING_TYPES | null
    floor_scale: string | null
    cutouts?: IMUP2DDrawableCutout[]
    three_d_identifier?: string | null
    label: string
    isActive: boolean
    additionalData: IndexableObject
    region_id: number | null
    ai_suggestion_id: string
}

export interface IMUP2DDrawableCutout {
    coordinates: Coordinate[]
}

export interface MeasurementsToUpdate {
    quantity: number | null
    linear_total: number | null
    area?: number | null
    count?: number | null
}

/* IMUP2DCoordinatesToUpdate
Object includes coordinates of update opening_location
and re-calculated measurements based on new coordinates (quantity/linear_total)
if measurements is null, it means the shaped has been moved without dragging vertices
cutouts of opening_location is optional parameter.
*/
export interface IMUP2DCoordinatesToUpdate {
    coordinates: Coordinate[]
    measurements: MeasurementsToUpdate
    cutouts?: IMUP2DDrawableCutout[]
    region_id?: number | null
}

export interface IMUP2DMultipleCoordinatesToUpdate extends Omit<IMUP2DCoordinatesToUpdate, 'coordinates'> {
    coordinates: Coordinates2D
    opening_id: number
    opening_location_id: number
}

export interface IMediator<T> {
    mediate<K extends keyof T>(key: K, newState: Partial<T[K]>): IMediator<T>
    get$(): Observable<T>
    dispose(): void
}

export interface ITool {
    name?: string
    cursor: string
    activate: () => void
    remove: () => void
}

export interface IToolBox extends IToolProvider {
    tools: Tools
    getTool: <K extends keyof Tools>(name: K) => Tools[K]
    getTools: <K extends keyof Tools>(names: K[]) => Tools[K][]
}

/**
 * Interface that that represents a material generator
 *
 */
export interface IMaterialGenerator {
    /**
     * Initialization for generators
     * @param scene the babylon scene
     */
    initGenerator(scene: Scene, structure?: IModel): void | Promise<void>

    /**
     * Trigger generator to apply materials to meshes
     */
    applyMaterial(meshes?: Mesh[]): void
}

export interface IGeometryMaterialOptions {
    ids: string[]
    optionsName?: string
}
export interface IToolProvider {
    getTool: (name: string) => ITool
    getTools: (names: string[]) => ITool[]
}

export interface IToolUser {
    useTool: (name: string) => ITool
    useTools: (names: string[]) => ITool[]
}

export type IStateMediator = IMediator<IMUPState>

export interface IManager extends IToolUser, IToolProvider {}

export interface ToolbarButtonItem {
    label: string
    toolName: string
    icon: ReactElement
    subMenuComponent?: JSX.Element
}

export interface ToolbarToggleItem {
    label: string
    storeProperty: string
    icon: ReactElement
}

export interface ToolbarButtonProps {
    manager: AbstractManager
    tool: ToolbarButtonItem
}

export interface IDesignOptions {
    wts_design_option_set_name: string | null
    wts_design_option_name: string | null
    wts_phase_name: string | null
    revitId: string | null
    revitUniqueId: string | null
    phaseRevitId: string | null
    phaseRevitUniqueId: string | null
}

export interface IModel {
    name: string
    type: string
    guid: string
    revitId: string
    interior: boolean
    index: number
    family: string
    tag: string | null
    geometryChecksum: string | null
    geometryMatrix: number[] | null
    children: IModel[]
    storeyName: string
    properties: Map<string, IMetadataProperty>
    splitFromElementGuid: string | null
    designOptions: IDesignOptions | null
}

export interface IMetadataProperty {
    name: string
    type: string
    value: any
    unit: string
}

export interface IStorey {
    guid: string
    children: IModel[]
    name: string
}

export interface IGeometryMetadata {
    isReflectedInTwoD: boolean
    modelType: string
    ids: TwoDSelectedItem[] | []
    storeyName: string
    isInterior: boolean
    label?: string
    isLandscaping?: boolean
    configuration_id: string | null
    additionalData?: IndexableObject
}

export interface IGeometry {
    name?: string
    checksum: string
    indices: number[]
    materialIndices: number[]
    materialNames: string[]
    metaData: IGeometryMetadata
    materials: number[]
    normals: number[]
    vertices: number[]
    uvs?: number[]
    configuration_id: string | null
}

export interface IBabylonPolygonContourWithMetadata {
    shape: number[]
    holes: number[]
    metaData: IGeometryMetadata
}

export interface IBabylonLineWithMetadata {
    path: IVector[]
    metaData: IGeometryMetadata
}

export interface IThreeDElementsRaw {
    '@id': string
    id: string
    version: number
    data: string
}

export interface IVector {
    x: number
    y: number
    z: number
}

export interface IContourPoint {
    point1: IVector
    point2: IVector
    vector: IVector
    number: number
    length: number
}

export interface IPolygonWithHoles {
    contour: IContourPoint[]
    holes: IContourPoint[]
    customRevitId: string
    customRevitIdBase: string
}

export interface IRoofJunctionData {
    number: number
    edge1Number: number
    edge2Number: number
    junctionType: string
    point1: IVector
    point2: IVector
    length: number
    valleyPlate: boolean
    customRevitId: string
    customRevitIdBase: string
}

export interface RoofFaceData {
    number: number
    area: number
    perimeter: number
    slopeRise: number
    slopeSpan: number
    contour: IContourPoint[]
    holes: IContourPoint[]
    customRevitIdBase: string
}
export interface IRoofEdgeData {
    number: number
    edgeNumber: number
    singleEdgeType: ModelType.GABLE | ModelType.FLASHING | ModelType.EAVE
    point1: IVector
    point2: IVector
    length: number
    overhangDistance: number
    customRevitId: string
    customRevitIdBase: string
}

export interface IFloorJunctionEdge {
    number: number
    point1: IVector
    point2: IVector
    vector: IVector
    length: IVector
}

export interface IFloorJunctionData {
    number: number
    floorId: string
    junctionElementId: string
    junctionType: FloorJunctionTypes
    junctionEdges: IFloorJunctionEdge[]
    customRevitId: string
    customRevitIdBase: string
}

export interface IOverframedRoofFaceData {
    number: number
    area: number
    perimeter: number
    slopeRise: number
    slopeSpan: number
    contour: IContourPoint[]
    holes: IContourPoint[]
}

export interface ICornerIntersection {
    length: number
    number: number
    point1: IVector
    point2: IVector
    vector: IVector
    wall1Id: string
    wall2Id: string
}

export interface ICornersData {
    angle: number
    intersections: ICornerIntersection[]
    length: number
    number: number
    point1: IVector
    point2: IVector
    vector: IVector
    customRevitId: string
    customRevitIdBase: string
}

export type CanvasProps = {
    ref: React.MutableRefObject<HTMLCanvasElement | null>
    className: string
    resize: string
    hidpi: string
    keepalive: string
}

export interface IOpeningEdgeData {
    p1: IVector
    p2: IVector
}

export interface IOpeningRaw {
    '@id': string
    id: string
    version: number
    meshId: string
    storeyId: string
    storeyName: string
    type: ModelType.DOOR | ModelType.WINDOW | ModelType.MYBUILD
    name: string
    spaceName: string
    spaceId: string
    data: string
    combination: null
    modelCombination: any
    orderNumber: number
    position: string
    normal: string
    combinationMaster: any
    customRevitId: string
    edgesMap: {
        CASING?: IOpeningEdgeData[]
        HEADER?: IOpeningEdgeData[]
        SILL?: IOpeningEdgeData[]
    }
}

export interface IMetadata {
    parentName: string
    comments: string
    name: string
    parentGuid: string
    parentIndex: number
    parentTag: string
    propertySets: string[][]
}

export enum DRAW_TOOL_ACTION {
    NEW_OPENING = 'NEW_OPENING',
    NEW_OPENING_LOCATION = 'NEW_OPENING_LOCATION',
}

export interface CalculateFeetFromPxInputForPath extends CalculateFeetFromPxInput {
    coordinates: Coordinate[]
}

export interface CalculateFeetFromPxInput {
    scaleFactor: number
    dpi: number | null
    pxValue: number
    xCalibrationFactor: number
    yCalibrationFactor: number
    pdfScale: number
    coordinates?: Coordinate[]
}

export interface CalculateFeetFromPxInputOneFactor {
    scaleFactor: number
    dpi: number | null
    pxValue: number
    calibrationFactor: number
    pdfScale: number
    coordinates?: Coordinate[]
}

export const toolsAllowedToUseForms: Set<string> = new Set([TOOL_TYPE_ENUMS.FLOOR_LEVEL_BREAK_LINE])

export enum MATERIAL_FORM_TABS {
    GENERAL = 'general',
    ADVANCED_SETTINGS = 'advanced_settings',
}

export interface SnappingSide {
    side: SNAPPING_SIDE
    point: paper.Point
}

export interface Property {
    code: string
    type: string | null
    occurrenceType: string | null
    value: [string, string][]
    defaultValue: string | null
}

export interface Variable {
    code: string
    properties: Property[]
}

export interface UpdateRuleEngineStateDto {
    code: string | null
    occurrenceCode: string | null
    uuid: string
    variables: Variable[]
    sessionId: string
}

export type BulkOpeningCreationData = {
    opening_group_id: number
    coordinates: Coordinate[]
    cutouts: IMUP2DDrawableCutout[]
    document_chunk_id: number
    measurements: MeasurementsToUpdate
    settings: {
        unit_of_measure: string
        shape_type?: string
    }
    region_id: number | null
    additional_data?: Record<string, any>
    ai_suggestion_id?: string
    floor_hash: string
}

export interface AutomatedObjectToCreate {
    document_chunk_id: number
    id: string
    coordinates: Coordinate[]
    measurements: MeasurementsToUpdate
    region_id: number | null
    additional_data?: Record<string, any>
}

export type BulkAIOpeningsCreationData = {
    type: DRAWABLE_TYPES
    settings: GeneralDrawableSettings
    rcm_building_id: string
    automated_objects: AutomatedObjectToCreate[]
}
