export function hydrateCasingMeshIdsFromOpeningIds(openingIds: string[]) {
    return openingIds.reduce((ids, id) => {
        if (id.includes('CASING')) {
            ids.push(`${id}+0`)
            ids.push(`${id}+1`)
        } else {
            ids.push(id)
        }

        return ids
    }, [] as string[])
}
