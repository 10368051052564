import store from '../../../stores'
import { multiSelectDrawables } from '../../slices/2D'
const DATA_ID_KEY = 'opening_location_id'

export function getDrawableIdsOfMultiSelectedGroup(items: paper.Item[]) {
    return Array.from(
        items.reduce((ids, item) => {
            ids.add(item.data[DATA_ID_KEY])

            return ids
        }, new Set<number>())
    )
}

export function createMultiSelectPredicates() {
    return {
        groupCreationPredicate: (item: paper.Item, rectangle: paper.Item) =>
            item.visible && (item.intersects(rectangle) || item.isInside(rectangle.bounds)),
        onMultiSelectCreateOrUpdate: (items: paper.Item[]) =>
            store.dispatch(multiSelectDrawables(getDrawableIdsOfMultiSelectedGroup(items))),
    }
}
