import { createAction } from '@reduxjs/toolkit'
import { call, put, SagaReturnType, select, takeEvery } from 'redux-saga/effects'

import { deleteProjectContextMarkups, deleteProjectDocumentChunks } from '../../../api/projects-api'
import { NormalizedFlag } from '../../../models/flags'
import { selectDrawableLocations, setDrawableLocations } from '../../slices/2D'
import { deleteDocumentChunks } from '../../slices/documents'
import { deleteGeometriesByIds, GeometricDrawable, selectDrawableGeometries } from '../../slices/geometry'
import { removeFlag, selectMaterialFlags } from '../../slices/materialFlags'
import { selectProjectId } from '../../slices/project'
import { removeToolObjectsByToolId } from '../../slices/tools'
import { IMUP2DDrawableLocation } from '../../types'
import { selectMarkupState } from '../2D/markupDocument'

export const deleteDocumentChunksAction = createAction<{ documentChunksIdsToDelete: number[] }>(
    'deleteDocumentChunksAction'
)

export function* getFlagIdsToDelete(documentChunksIdsToDelete: number[]) {
    const flags: NormalizedFlag[] = yield select(selectMaterialFlags)

    const flagIds: number[] = []

    // get the flag ids that we need remove from store
    documentChunksIdsToDelete.forEach((idToDelete) => {
        for (const flag of flags) {
            const openingLink = flag.opening_links.find((link) => link.document_chunk_id === idToDelete)

            if (openingLink) flagIds.push(flag.id)
        }
    })

    return flagIds
}

export function* handleDeleteDocumentChunks(action: ReturnType<typeof deleteDocumentChunksAction>) {
    const { documentChunksIdsToDelete } = action.payload

    try {
        const projectId: any = yield select(selectProjectId)

        if (!projectId) return

        const deletionResult: SagaReturnType<typeof deleteProjectDocumentChunks> = yield call(
            deleteProjectDocumentChunks,
            projectId,
            documentChunksIdsToDelete
        )

        // dispatch delete action with the result from the API call
        yield put(deleteDocumentChunks({ documentChunksIdsToDelete: deletionResult.deletedDocumentChunks }))

        const flagIds = yield call(getFlagIdsToDelete, documentChunksIdsToDelete)

        yield put(removeFlag({ flag_ids: flagIds }))

        const drawableLocations: IMUP2DDrawableLocation[] = yield select(selectDrawableLocations)

        const drawableGeometries: GeometricDrawable[] = yield select(selectDrawableGeometries)

        const drawableLocationIdsToDelete: number[] = documentChunksIdsToDelete.flatMap((documentChunkId) =>
            drawableLocations
                .filter((drawableLocation) => drawableLocation.document_chunk_id === documentChunkId)
                .map((drawableLocation) => drawableLocation.drawable_id)
        )

        if (!!drawableLocationIdsToDelete.length) {
            const restLocations = drawableLocations.filter(
                (location) => !drawableLocationIdsToDelete.includes(location.drawable_id)
            )

            // remove drawable locations to not display them on blueprint
            yield put(setDrawableLocations(restLocations))

            const geometryIdsToDelete = drawableGeometries
                .filter((geometry) => drawableLocationIdsToDelete.includes(geometry.id))
                .map((geometry) => geometry.id)

            // remove geometries by id to update the opening groups
            yield put(deleteGeometriesByIds(geometryIdsToDelete))
        }

        const { toolObjects }: ReturnType<typeof selectMarkupState> = yield select(selectMarkupState)
        const pageToolObjectsIdsToDelete: number[] = toolObjects
            .filter((toolObject) =>
                documentChunksIdsToDelete.some((documentChunkId) => documentChunkId === toolObject.document_chunk_id)
            )
            .map((tObj) => tObj.id)

        yield call(deleteProjectContextMarkups, pageToolObjectsIdsToDelete)

        // remove  tool objects from store
        yield put(removeToolObjectsByToolId(pageToolObjectsIdsToDelete))
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForDocumentChunksDelete() {
    yield takeEvery(deleteDocumentChunksAction.type, handleDeleteDocumentChunks)
}
